.service-monitoring-page {
  .sm-container {
    display: flex;
    flex-direction: column;
    gap: 22px;
    padding: 28px 32px 28px 32px;
  
    .sm-inputs-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .sm-title-page {
        display: flex;
        align-items: center;
        gap: 32px;

        &.variant {
          flex-direction: column;
          align-items: start;
          gap: 12px;
          font-size: 14px;
        }
      }

      .sm-inputs {
        display: flex;
        align-items: center;
        gap: 16px;
  
        input {
          height: 40px;
        }
      }
    }

    .sm-table {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .grid-pagination{
        position: relative;
        justify-content: center;
        .pagination-info{
          position: absolute;
          left: 0;
        }
      }

      .frame {
        display: flex;
        height: auto;
        overflow: hidden;
      }

      .sm-grid {
        .column-0 {
          width: 56px;
          cursor: pointer;
          position: relative;
        }
        .column-1 {
          width: 33%;
        }
        .column-2 {
          width: 33%;
        }
        .column-3 {
          width: auto;
        }
        .settings-column {
          width: 56px;
          .more-vert{
            color: #A6AEB9;
          }
          &:hover{
            color: #1665a7;
            cursor: pointer;
          }
        }
        .checkbox-column {
          width: 56px;
        }
        .service-status-formatter-box{
          width: 134px;
        }
      }
      
      .table .data-table-content .data-table-row .data-table-cell {
        height: 60px;
      }
      
      .table .data-table-header .table-header-row .table-header-cell{
        padding: 16px;
      }
      
      .sticky-table-wrapper .table .data-table-header{
        box-shadow: none;
      }
    } 
  }
}

.select-toggle {
  &.active {
    .form-select {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
.dropdown-menu-portal {
  .dropdown-menu {
    top: 100%;
    box-shadow: 0px 10px 30px 0px #00000026;
    border-radius: 0 0 4px 4px;
    border: none;
    &.center{
      left: -300%;
    }
  }
}

.dropdown-menu-portal {
  position: absolute;
  z-index: 2000;
  .dropdown-menu-open-top {
    top:0;
  }
}

.dropdown-toggle::after {
  display: none;
}

  .map{
    width: 100%;
    height: 892px;
    background-color: white;
    box-shadow: 0px 2px 10px 0px #00000021;
    padding: 24px;
    position: relative;

    .map-item{
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      height: 42px;
      width: 42px;
      border-radius: 100%;
      background-color: #1665A7;
      z-index: 99999;

      .map-item-wrapper{
        position: relative;
        
        .map-item-title{
          position: absolute;
          display: none;
          justify-content: center;
          align-items: center;
          width: 240px;
          height: 48px;
          box-shadow: 0px 2px 10px 0px #00000021;
          background-color: white;
          top: -80px;
          left: -110px;
          color: #213450;
          font-size: 16px;
          border-radius: 4px;
        }
        .map-item-title::after{
          content: ''; 
          position: absolute;
          bottom: -30px;
          border: 16px solid transparent;
          border-top: 20px solid white;
        }
        .map-item-icon{
          pointer-events: none;
        }
      }
    }

    .map-item:hover{
      background-color:#91BADC;
      .map-item-title{
        display: flex;
      }
      cursor: pointer;
    }
    
    .map-button-box{
      position: absolute;
      display: flex;
      right: 32px;
      top: 42%;
      flex-direction: column;
      box-shadow: 0px 10px 30px 0px #00000026;
      width: 48px;
      .map-button{
        z-index: 999999999;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        color: #758192;
        width: 48px;
        background: white;
        border-bottom: 1px solid #758192;
        user-select: none;
        border-radius: 4px 4px 0px 0px;
      }
      .map-button:hover{
        cursor: pointer;
      }
      .map-button:last-child{
        border-bottom: none;
        border-radius: 0px 0px 4px 4px;
      }
    }
    .map-button-center{
      position: absolute;
      display: flex;
      cursor: pointer;
      background-color: white;
      justify-content: center;
      align-items: center;
      top: 32px;
      right: 32px;
      box-shadow: 0px 2px 10px 0px #00000021;
      border-radius: 4px;
      width: 68px;
      height: 36px;
      border-radius: 4px;
      z-index: 999999999;
    }
    .map-button-editor{
      position: absolute;
      display: flex;
      cursor: pointer;
      background-color: white;
      justify-content: center;
      align-items: center;
      top: 32px;
      right: 110px;
      box-shadow: 0px 2px 10px 0px #00000021;
      border-radius: 4px;
      width: 68px;
      height: 36px;
      border-radius: 4px;
      z-index: 999999999;
    }
  }

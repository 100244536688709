.client-page {
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 100%;
  .client-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 22px;
    padding: 28px 32px 28px 32px;
  
    .client-inputs-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .client-title-page {
        display: flex;
        align-items: center;

        &.variant {
          flex-direction: column;
          align-items: start;
          gap: 12px;
          font-size: 14px;
        }
      }

      .client-inputs {
        display: flex;
        align-items: center;
        gap: 16px;
  
        input {
          height: 40px;
        }

      }
    }

    .client-form{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      padding-top: 24px;
      flex: 1;
      background-color: white;
      gap: 16px;
      border-radius: 4px;
      box-shadow: 0px 2px 10px 0px #00000021;

      .client-form-title{
        color: #001636DE;
        font-weight: 682;
        flex-shrink: 0;
        font-size: 18px;
        line-height: 25px;
        padding: 0 32px;
      }

      .client-form-data{
        display: flex;
        height: 100%;
        width: 100%;
        overflow: hidden;
        justify-content: space-between;
        align-items: flex-start;

        .client-form-data-box{
          width: 54%;
          flex-wrap: wrap;
          padding-left: 32px;
          justify-content: space-between;
          display: flex;
          gap: 16px;
          padding-right: 8px;

          .form-control-wrapper{
            width: 100%;
            .form-label{
              font-weight: 500;
              font-size: 16px;
              margin-bottom: 12px;
              line-height: 19px;
            }
            .form-control,.form-select{
              border: 0;
              background-color: #F4F7FB;
              border-radius: 4px;
              padding: 15px 16px;
              line-height: 22px;
              font-size: 16px;
              color: #758192;
            }
            .input-group{
              border: 0;
            }
            .input-group-text{
              background-color: #F4F7FB;
            }
            &.short{
              width: calc((100% / 2) - 8px);
            }
          }
        }

        .client-form-data-card-wrapper{
          display: flex;
          flex-direction: column;
          width: calc(46% - 24px);
          padding-right: 32px;
          z-index: 9999;
          gap:24px;

          .client-card-info{
            width: 100%;
          }

          .client-form-data-controls{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;

            .extend{
              width: 198px;
            }
          }
        }

      }

      .client-form-control{
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        gap: 16px;
        box-shadow: 0px 8px 26px 0px #00000040;
        border-radius: 4px;
      }
    }

    .client-table {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .frame {
        flex: 1;
        display: flex;
        height: auto;
        overflow: hidden;
      }

      .grid-pagination{
        position: relative;
        justify-content: center;
        .pagination-info{
          position: absolute;
          left: 0;
        }
      }

      .client-grid {
        .id-column {
          min-width: 10.4%;
          width: auto;
        }
        .type-column {
          width: 12.25%;
        }
        .lpn-column {
          width: 8.8%;
        }
        .parking-zone-column {
          width: 17.4%;
        }
        .period-column {
          width: 10%;
        }
        .status-column {
          width: 12.3%;
        }
        .control-column {
          width: 12.3%;
        }
        .settings-column {
          width: 56px;
          .more-vert{
            color: #A6AEB9;
          }
          &:hover{
            color: #1665a7;
            cursor: pointer;
          }
        }
        .checkbox-column {
          width: 56px;
        }
      }

      .table .data-table-content .data-table-row .data-table-cell {
        height: 60px;
      }
      
      .table .data-table-header .table-header-row .table-header-cell{
        padding: 16px;
      }
      .control-box-button {
        color: #1665A7;
        &:hover {
          color: white;
          background: #1665A7;;
          cursor: pointer;
        }
      }
    }
   
    .client-card-basic-information{
      display: flex;
      flex-shrink: 0;
      width: 23.5%;
      background-color: white;
      flex-direction: column;
      box-shadow: 0px 2px 10px 0px #00000021;
      border-radius: 4px;
      padding: 24px;
      gap: 16px;

      .client-card-basic-img{
        width: 372px;
        height: 245px;
      }

      .client-card-basic-information-title{
        font-size: 18px;
        font-weight: 700;
      }

      .client-card-basic-information-box{
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        border: 1px solid #E2E8F1;
        width: 100%;

        .client-card-basic-information-box-item{
          display: flex;
          height: 60px;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid #E2E8F1;

          &:last-child{
            border-bottom: 0;
          }

          .client-card-basic-information-box-item-title, .client-card-basic-information-box-item-content{
            display: flex;
            width: 50%;
            justify-content: flex-start;
            font-size: 16px;
            padding-left: 16px;
            align-items: center;
            height: 100%;
            position: relative;
          }
          .client-card-basic-information-box-item-title{
            font-weight: 500;
            border-right: 1px solid #E2E8F1;
          }
        }

        .client-card-basic-control-button{
          position: absolute;
          display: flex;
          width: 36px;
          height: 36px;
          color: #1665A7;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          box-shadow: 0px 2px 10px 0px #1665A7;
          cursor: pointer;
          right: 16px;
          bottom: 12px;
        }
      }
      
    }

    .client-card-info{
      display: flex;
      width: 556px;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      border: 1px solid #E2E8F1;
      padding: 56px 0px;
      border-radius: 4px;

      .client-card-info-img{
        width: 286px;
        height: 212px;
        margin-bottom: 57px;
      }

      .client-card-info-item{
        display: flex;
        width: 360px;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .client-card-info-item-title{
          font-size: 18px;
          line-height: 25px;
        }

        .client-card-info-item-data{
          line-height: 29px;
          font-size: 24px;
        }

        &.gov{
          flex-direction: column;
          margin-bottom: 24px;
        }

        &:last-child{
          margin-bottom: 0;
        }
      }   
    }
  }
}

.client-status{
  display: flex;
  justify-content: space-between;
  min-height: 872px;
  height: 100%;

  .client-basic-information{
    display: flex;
    flex-shrink: 0;
    width: 25.7%;
    background-color: white;
    flex-direction: column;
    box-shadow: 0px 2px 10px 0px #00000021;
    border-radius: 4px;
    padding: 24px;
    gap: 16px;

    .client-basic-img{
      background-size: cover;
      width: 372px;
      height: 245px;
    }

    .client-basic-information-title{
      font-size: 18px;
      font-weight: 700;
    }

    .client-basic-information-box{
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      border: 1px solid #E2E8F1;
      width: 100%;

      .client-basic-information-box-item{
        display: flex;
        height: 60px;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #E2E8F1;

        &:last-child{
          border-bottom: 0;
        }

        .client-basic-information-box-item-title, .client-basic-information-box-item-content{
          display: flex;
          width: 50%;
          justify-content: flex-start;
          font-size: 16px;
          padding-left: 16px;
          align-items: center;
          height: 100%;
          position: relative;
        }
        .client-basic-information-box-item-title{
          font-weight: 500;
          border-right: 1px solid #E2E8F1;
        }
        .client-status-formatter-box{
          width: 170px;
        }
      }

      .client-basic-control-button{
        position: absolute;
        display: flex;
        width: 36px;
        height: 36px;
        color: #1665A7;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        box-shadow: 0px 2px 10px 0px #1665A7;
        cursor: pointer;
        right: 16px;
        bottom: 12px;
      }
    }
    
  }

  .client-status-table-box{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: calc(74.3% - 8px);
    border-radius: 4px;

    .client-status-tab-menu{
      display: flex;
      justify-content: flex-start;
      background-color: white;
      border-radius: 4px;
      height: 64px;
      box-shadow: 0px 2px 10px 0px #00000021;

      .client-status-tab-item{
        display: flex;
        height: 64px;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #758192;
        width: 200px;
        &:hover{
          border-bottom: 3px solid #1665A7;
          color: #1665A7;
          cursor: pointer;
        }
        &.selected{
          color: #1665A7;
          border-bottom: 3px solid #1665A7;
        }
      }
    }

    .client-status-tables-container{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      background-color: white;
      padding: 24px;
      border-radius: 4px;
      gap: 16px;
      box-shadow: 0px 2px 10px 0px #00000021;


      .client-status-tables-container-title{
      display: flex;
      border-radius: 4px;
      justify-content: space-between;

      .client-status-title{
        font-weight: 500;
        font-size: 18px;
      }
      .client-status-show-table{
        color: #1665A7;
        font-weight: 500;
        font-size: 18px;
        cursor: pointer;
      }
      }

      .frame {
        display: flex;
        height: auto;
        overflow: hidden;
        box-shadow: none;
        border: 1px solid #E2E8F1;
      }

      .slp-table {
        .column-0 {
          width: 17%;
        }
        .column-1 {
          width: 16%;
        }
        .column-2 {
          width: 19%;
        }
        .column-3 {
          width: 14%;
        }
        .column-4 {
          width: 17%;
        }
        .column-5 {
          width: 12.5%;
        }
        .column-6 {
          width: auto;
        }
        .settings-column {
          width: 56px;
          .more-vert{
            color: #A6AEB9;
          }
          &:hover{
            color: #1665a7;
            cursor: pointer;
          }
        }
        .checkbox-column {
          width: 56px;
        }
        .session-status-formatter-box{

        }
      }

      .plp-grid {
        .column-4 {
          width: 13%;
        }
        .column-5 {
          width: 14.5%;
        }
        .column-6 {
          width: 13%;
        }
        .column-7 {
          width: 13%;
        }
        .column-8 {
          width: 13%;
        }
        .column-9 {
          width: auto;
        }
        .settings-column {
          width: 56px;
          .more-vert{
            color: #A6AEB9;
          }
          &:hover{
            color: #1665a7;
            cursor: pointer;
          }
        }
        .checkbox-column {
          width: 56px;
        }
      }


      .table .data-table-content .data-table-row .data-table-cell {
        height: 40px;
      }
      
      .table .data-table-header .table-header-row .table-header-cell{
        padding: 16px;
      }

      .sticky-table-wrapper .table .data-table-header{
        box-shadow: none;
      }
    }
  }
}

.client-card{
  --size: 38px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .client-card-title{
    font-size: 18px;
    font-weight: 700;
    text-align: start;
  }

  .client-card-box{
    display: flex;
    justify-content: space-between;
    
    .client-card-info{
      display: flex;
      width: calc(58% - 16px);
      flex-direction: column;
      justify-content: start;
      align-items: center;
      border: 1px solid #E2E8F1;
      padding: 56px 0px;
      border-radius: 4px;

      .client-card-info-img{
        width: 286px;
        height: 212px;
        margin-bottom: 57px;
      }

      .client-card-info-item{
        display: flex;
        width: 360px;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .client-card-info-item-title{
          font-size: 18px;
          line-height: 25px;
        }

        .client-card-info-item-data{
          line-height: 29px;
          font-size: 24px;
        }

        &.gov{
          flex-direction: column;
          margin-bottom: 24px;
        }

        &:last-child{
          margin-bottom: 0;
        }
      }   
    }

    .client-card-basic-information{
      display: flex;
      flex-shrink: 0;
      width: 42%;
      background-color: white;
      flex-direction: column;
      border-radius: 4px;
      gap: 16px;

      .client-card-basic-information-box{
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        border: 1px solid #E2E8F1;
        width: 100%;

        .client-card-basic-information-box-item{
          display: flex;
          height: 60px;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid #E2E8F1;

          &:last-child{
            border-bottom: 0;
          }
          .client-card-basic-information-box-item-title, .client-card-basic-information-box-item-content{
            display: flex;
            width: 46%;
            justify-content: flex-start;
            font-size: 16px;
            padding-left: 16px;
            align-items: center;
            height: 100%;
            position: relative;
          }
          .client-card-basic-information-box-item-content{
            width: 54%;
          }
          .client-card-basic-information-box-item-title{
            font-weight: 500;
            border-right: 1px solid #E2E8F1;
          }
        }

        .client-card-basic-control-button{
          position: absolute;
          display: flex;
          width: 36px;
          height: 36px;
          color: #1665A7;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          box-shadow: 0px 2px 10px 0px #1665A7;
          cursor: pointer;
          right: 16px;
          bottom: 12px;
        }
      }
      
    }
  }
}

.client-popup{
  border-radius: 4px;
  .modal-dialog {
    z-index: 999999999;
    .modal-content {
      height: 227px;
      border-radius: 4px;
      border: 0;
      .hff-title {
        padding: 24px 32px 16px 32px;
        .header-buttons{
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
        }
        border: 0;
      }
      .hff-content {
       text-align: center;
        font-size: 18px;
        padding: 0 32px;
      }
      .hff-footer{
        justify-content: center;
        align-items: center;
        border: 0;
        height: 80px;
        gap: 16px;
      }
    }
  }
  .modal-xl {
    --bs-modal-width: 524px;
  }
}

.client-popup-details{
  border-radius: 4px;
  .modal-dialog {
    z-index: 999999999;
    .modal-content {
      height: 750px;
      border-radius: 4px;
      border: 0;
      .hff-title {
        padding: 24px 32px 16px 32px;
        .header-buttons{
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0;
        }
        .hff-close-wrapper{
          cursor: pointer;
          color: #758192;
        }
        border: 0;
      }
      .hff-content {
        padding: 0 32px;
        .client-card{
          .client-card-info-item{
            margin-bottom: 8px;
          }
          .client-card-info-img{
            margin-bottom: 16px;
          }
        }
      
      }
      .hff-footer{
        justify-content: end;
        align-items: center;
        border: 0;
        border-top: 1px solid #D4D9E3;
        height: 80px;
        gap: 16px;
      }
    }
  }
  .modal-xl {
    --bs-modal-width: 1044px;
  }
}

.client-popup-delete {
  .modal-dialog {
    z-index: 999999999;
    .page-header {
      .header-buttons{
        justify-content: center;
      }
    }
    .hff-footer{
      justify-content: center;
    }
    .modal-body{
      display: flex;
      flex-wrap: wrap;
      gap: 12px;

      .delete-item{
        display: flex;
        height: 40px;
        width: 164px;
        justify-content: space-between;
        align-items: center;
        background-color: #EAF5FF;
        font-size: 16px;
        color: #213450;
        padding: 8px 12px;
        .delete-item-text{
          width: 110px;
          white-space: nowrap; /* Запрещаем перенос текста */ 
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .hff-close{
          color: #758192;
          cursor: pointer;
        }
      }
    }
  }
  .modal-xl {
    --bs-modal-width: 564px;
  }
}
.modal {
  .modal-backdrop {
    background: #213450;
    opacity: 0.25;
  }
  .modal-dialog {
    .modal-content {
      .page-header {
        .header-buttons {
          padding-right: 16px;
          .close {
            width: 14px;
            height: 14px;
            color: #758192;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.confirm-modal {
  z-index: 99999;
  .modal-dialog {
    z-index: 2222;
  }
}

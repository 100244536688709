.custom-button{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 172px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  gap: 8px;
  background-color: #1665A7;
  border-radius: 4px;
  cursor: pointer;

  &:hover{
    background-color: #085290;
    &.isCancel{
      background-color: #EAF5FF;
    }
  }

  &.isCancel{
    height: 38px;
    background-color: white;
    border: 2px solid #1665A7;
    color: #1665A7;
  }
  &.disable{
    background-color: #A6AEB9;
  }
  
  &.small{
    width: 122px;
  }
}
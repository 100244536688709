.grid-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px;
  font-size: 13px;
  color: #213450;
  .pagination-info{
    display: flex;
    align-items: center;
  }
  .pagination-count-text {
    color: #213450;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 143%;
  }
  .pagination-page-limit-select {
    .form-select {
      background-color: white;
      border: none;
      box-shadow: 0px 2px 10px 0px #00000021;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 143%;
      cursor: pointer;
    }
  }
  .pagination-count-value {
    color: #213450;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 143%;
    .pagination-count-total {
      color: #758192;
    }
  }
  .pagination-pages {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 6px;
    .pagination-button {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 143%;
      cursor: pointer;
      &.active {
        border-radius: 32px;
        background-color: #1665A7;
        color: #ffffff;
      }

      .item-icon {
        width: 7px;
        height: 10px;
        color: #758192;
        &.disabled {
          opacity: 0.4;
        }
      }
      // &:first-child {
      //   margin-left: -167px;
      // }
    }
  }
}


.mobile-layout {
  .grid-pagination {
    .pagination-count-text {
      display: none;
    }
    .pagination-page-limit-select {
      display: none;
    }
    .pagination-count-value {
      display: none;
    }
    .pagination-pages {
      .pagination-button {
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

.form-control-wrapper {
  .custom-select {
    margin-left: 0!important;
    .input-group {
      overflow: hidden;
      border-radius: 4px;
      border: 0;
      &:focus-within {
        border: 0;
        box-shadow: 0 0 0 1px #1665A7;
      }
      .input-group-text {
        z-index: 1000;
        padding-top: 0;
        padding-bottom: 0;
        box-shadow: -10px 0px 17px 0px white;
      }
    }
  }
  .form-select {
    vertical-align: top;
    position: relative;
    width: 100%;
    border: 1px solid #B3B9C3;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    &:focus,
    &:focus-within {
      border: 1px solid #1665A7;
      box-shadow: 0 0 0 1px #1665A7;
      .form-input-label {
        top: -10px;
        font-size: 12px;
        background: #fff;
      }
    }
    .form-control {
      width: 100%;
      height: 56px;
      border: none;
      box-shadow: none;
      border-radius: 3px;
      font-size: 16px;
    }
    .form-input-label {
      position: absolute;
      pointer-events: none;
      left: 15px;
      top: -10px;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      transition: .3s;
      padding: 0 4px;
      background: #fff;
    }
    .form-control[value=""]~.form-input-label {
      top: 8px;
      font-size: 16px;
      background: transparent;
    }
    .form-control:-webkit-autofill~.form-input-label,
    .form-control:focus~.form-input-label {
      top: -10px;
      font-size: 12px;
      background: #fff;
    }
    .input-before-block {
      margin-left: 16px;
    }
  }
  .select-arrow-wrapper {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    right: 12px;
    z-index: 22;
    padding: 8px 6px;
    cursor: pointer;
    background-color: transparent;
    .select-arrow {
      width: 12px;
      height: 8px;
      color: #758192;
    }
  }

  &.big {
    .form-select {
      height: 58px;
      .form-control[value=""]~.form-input-label {
        top: 16px;
      }
      .form-control:-webkit-autofill~.form-input-label,
      .form-control:focus~.form-input-label {
        top: -10px;
      }
    }
  }
  &.no-border {
    .form-select {
      border: none!important;
    }
    .select-toggle.active {
      .input-block {
        border: none!important;
      }
    }
  }
}

.portal-select-options {
  pointer-events: none;
  .select-options-wrapper {
    pointer-events: all;
    min-width: 100%;
    margin-top: -1px;
    padding: 6px 0;
    overflow: auto;
    .select-options-list {
      .select-option {
        font-size: 16px;
        font-weight: 400;
        padding: 12px;
        border-bottom: 1px solid #E2E8F1;
        &:last-child {
          border-bottom: none;
        }
        &.selected,
        &:hover {
          cursor: pointer;
          background-color: #EAF5FF;
        }
        &.updatePeriod{
          width: 212px;
        }
      }
    }
  }
}

.select-toggle {
  &.active {
    .input-block {
      border: 1px solid #1665A7;
      box-shadow: 0 0 0 1px #1665A7, 0 10px 30px 0 rgba(0, 0, 0, 0.15);
      .form-input-label {
        top: -10px!important;
        font-size: 12px!important;
        background: #fff!important;
      }
    }
  }
  .input-after-block {
    height: 24px;
  }
}

@media (max-width: 1799px) {
  .portal-select-options {
    .select-options-wrapper {
      .select-options-list {
        .select-option {
          font-size: 13px;
        }
      }
    }
  }
  .form-select {
    font-size: 13px;
    line-height: 1;
    height: 32px!important;
  }
}

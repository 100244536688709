.grid-wrapper {
  border-radius: 4px;
  border: 1px solid #E2E8F1;
  overflow: hidden;
}

.table {
  margin-bottom: 0;
  > :not(caption) > * > * {
    border-right-width: 1px;
    &:last-child {
      border-right-width: 0;
    }
  }
  tr:last-child {
    td {
      border-bottom-width: 0
    }
  }

  .data-table-header {
    .table-header-row {
      .table-header-cell {
        font-size: 16px;
        line-height: 16px;
        font-weight: 500;
        padding: 16px;
        color: #213450;
        vertical-align: middle;
        box-shadow: inset 0px -1px 0px 0px #dee2e6;
        border-bottom: 0;
        .table-column-wrapper {
          .table-column {
            line-height: 19px;
            flex: 1;
          }
          .table-column-sorted {
            width: 1rem;
            flex-shrink: 0;
            .bi {
              color: #213450;
              width: 8px;
              height: 8px;
              margin: 0 4px;
              opacity: 0.54;
            }
          }
        }
        &.sorted-column {
          cursor: pointer;
        }
        &.revert-sort {
          .table-column-wrapper {
            .table-column-sorted {
              .bi {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
      .checkbox-column {
        width: 56px;
        cursor: pointer;
        position: relative;
      }
    }
  }

  .data-table-content {
    .data-table-row {
      .data-table-cell {
        padding: 19px 16px;
        font-size: 16px;
        line-height: 19px;
        color: #213450;
        background: none;
      }
    }
    .data-table-row:hover {
      background-color: #EAF5FF;
      color:  #EAF5FF;
      cursor: pointer;
    }
    .empty-text {
      color: #758192;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.sticky-table-wrapper {
  min-height: 100%;
  height: 100%;
  width: 100%;
  overflow: auto;
  .table {
    margin-bottom: 0;
    .data-table-header {
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 999;
      box-shadow: inset 0px -1px 0px 0px #dee2e6;
      th {
        //background: transparent;
      }
    }
    &.empty{
      min-height: 100%;
    }    
  }
}

.row-border.table {
  > :not(caption) > * > * {
    border-right-width: 0;
    &:last-child {
      border-right-width: 0;
    }
  }
}

.no-border.table {
  > :not(caption) > * > * {
    border: 0;
  }
}

.vertical-grid {
  .data-table-content .data-table-row {
    .data-table-cell {
      padding: 8px 24px 8px 0;
      border-width: 0;
      &:first-child {
        color: #213450;
        font-size: 16px;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
      }
    }
    &:hover {
      background-color: #EAF5FF;
      color:  #EAF5FF;
      cursor: pointer;
    }
  }
}

@media (max-width: 1799px) {
  .table {
    .data-table-header {
      .table-header-row {
        .table-header-cell {
          font-size: 14px;
        }
      }
    }
    .data-table-content {
      .data-table-row {
        .data-table-cell {
          font-size: 13px;
        }
      }
    }
  }
}
.grid-wrapper {
  .settings-column {
    width: 56px;
    color: #758192!important;
    cursor: pointer;
  }
  .checkbox-column {
    width: 56px;
  }
}

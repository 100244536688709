.session-log-page {

  .slp-container {
    display: flex;
    flex-direction: column;
    gap: 22px;
    padding: 28px 32px 28px 32px;
  
    .slp-inputs-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .slp-title-page {
        display: flex;
        align-items: center;
        gap: 32px;

        &.variant {
          flex-direction: column;
          align-items: start;
          gap: 12px;
          font-size: 14px;
        }
      }

      .slp-inputs {
        display: flex;
        align-items: center;
        gap: 16px;

        input {
          height: 40px;
        }
      }
    }

    .slp-table {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .grid-pagination{
        position: relative;
        justify-content: center;
        .pagination-info{
          position: absolute;
          left: 0;
        }
      }

      .frame {
        display: flex;
        height: auto;
        overflow: hidden;
      }

      .slp-grid {
        .column-0 {
          width: 56px;
          cursor: pointer;
          position: relative;
        }
        .column-1 {
          width: 9.53%;
        }
        .column-2 {
          width: 9.53%;
        }
        .column-3 {
          width: 8.1%;
        }
        .column-4 {
          width: 8.1%;
        }
        .column-5 {
          width: 8%;
        }
        .column-6 {
          width: 8%;
        }
        .column-7 {
          width: 7.8%;
        }
        .column-8 {
          width: 10.5%;
        }
        .column-9 {
          width: 7.15%;
        }
        .column-10 {
          width: 11%;
        }
        .column-11 {
          width: auto;
        }
        .settings-column {
          width: 56px;
          .more-vert{
            color: #A6AEB9;
          }
          &:hover{
            color: #1665a7;
            cursor: pointer;
          }
        }
        .checkbox-column {
          width: 56px;
        }
      }

      .table .data-table-content .data-table-row .data-table-cell {
        height: 60px;
      }
      
      .table .data-table-header .table-header-row .table-header-cell{
        padding: 16px;
      }

      .sticky-table-wrapper .table .data-table-header{
        box-shadow: none;
      }
      
    }
    
    .slp-status{
      display: flex;
      justify-content: space-between;
      min-height: 872px;
      height: 100%;
  
      .slp-basic-information{
        display: flex;
        flex-shrink: 0;
        width: 23.5%;
        background-color: white;
        flex-direction: column;
        box-shadow: 0px 2px 10px 0px #00000021;
        border-radius: 4px;
        padding: 24px;
        gap: 16px;

        .slp-basic-img{
          background-size: cover;
          width: 372px;
          height: 245px;
        }

        .slp-basic-information-title{
          font-size: 18px;
          font-weight: 700;
        }

        .slp-basic-information-box{
          display: flex;
          flex-direction: column;
          border-radius: 4px;
          border: 1px solid #E2E8F1;
          width: 100%;

          .slp-basic-information-box-item{
            display: flex;
            height: 60px;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid #E2E8F1;

            &:last-child{
              border-bottom: 0;
            }

            .slp-basic-information-box-item-title, .slp-basic-information-box-item-content{
              display: flex;
              width: 50%;
              justify-content: flex-start;
              font-size: 16px;
              padding-left: 16px;
              align-items: center;
              height: 100%;
              position: relative;
            }
            .slp-basic-information-box-item-title{
              font-weight: 500;
              border-right: 1px solid #E2E8F1;
            }
          }

          .slp-basic-control-button{
            position: absolute;
            display: flex;
            width: 36px;
            height: 36px;
            color: #1665A7;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            box-shadow: 0px 2px 10px 0px #1665A766;
            cursor: pointer;
            right: 16px;
            bottom: 12px;
          }
        }
        
      }

      .slp-status-table-box{
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: calc(76.5% - 8px);
        border-radius: 4px;

        .slp-status-tab-menu{
          display: flex;
          justify-content: flex-start;
          background-color: white;
          border-radius: 4px;
          height: 64px;
          box-shadow: 0px 2px 10px 0px #00000021;

          .slp-status-tab-item{
            display: flex;
            height: 64px;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            color: #758192;
            width: 200px;
            &:hover{
              border-bottom: 3px solid #1665A7;
              color: #1665A7;
              cursor: pointer;
            }
            &.selected{
              color: #1665A7;
              border-bottom: 3px solid #1665A7;
            }
          }
        }

        .slp-status-tables-container{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          background-color: white;
          padding: 24px;
          border-radius: 4px;
          gap: 16px;
          box-shadow: 0px 2px 10px 0px #00000021;


          .slp-status-tables-container-title{
          display: flex;
          border-radius: 4px;
          justify-content: space-between;

          .slp-status-title{
            font-weight: 500;
            font-size: 18px;
          }
          .slp-status-show-table{
            color: #1665A7;
            font-weight: 500;
            font-size: 18px;
            cursor: pointer;
          }
          }

          .frame {
            display: flex;
            height: auto;
            overflow: hidden;
            box-shadow: none;
            border: 1px solid #E2E8F1;
          }

          .elp-table {
            .column-1 {
              width: 12%;
            }
            .column-2 {
              width: 12.2%;
            }
            .column-3 {
              width: 7.8%;
            }
            .column-4 {
              width: 7.8%;
            }
            .column-5 {
              width: 7.8%;
            }
            .column-6 {
              width: 4.6%;
            }
            .column-7 {
              width: auto;
            }
            .settings-column {
              width: 56px;
              .more-vert{
                color: #A6AEB9;
              }
              &:hover{
                color: #1665a7;
                cursor: pointer;
              }
            }
            .checkbox-column {
              width: 56px;
            }
          }

          .plp-grid {
            .column-4 {
              width: 13%;
            }
            .column-5 {
              width: 14.5%;
            }
            .column-6 {
              width: 13%;
            }
            .column-7 {
              width: 13%;
            }
            .column-8 {
              width: 13%;
            }
            .column-9 {
              width: auto;
            }
            .settings-column {
              width: 56px;
              .more-vert{
                color: #A6AEB9;
              }
              &:hover{
                color: #1665a7;
                cursor: pointer;
              }
            }
            .checkbox-column {
              width: 56px;
            }
          }
    

          .table .data-table-content .data-table-row .data-table-cell {
            height: 60px;
          }
          
          .table .data-table-header .table-header-row .table-header-cell{
            padding: 16px;
          }

          .sticky-table-wrapper .table .data-table-header{
            box-shadow: none;
          }
        }
      }
    }
  }
}

.slp-edit-popup{
  .modal-dialog {
    z-index: 999999999;
    .modal-content {
      height: 305px;
      border-radius: 4px;
      border: 0;
      
      .hff-title {
        padding: 24px 32px 16px 32px;
        border-bottom: 1px solid #D4D9E3;
        position: relative;

        .header-buttons{
          padding: 0;
          display: flex;
          justify-content: space-between;

          .hff-close-wrapper {
            padding: 0;
            .hff-close {
              color: #758192;
              cursor: pointer;
              &:hover {
                color: #1665A7;
              }
            }
          }
        }
        
      }
      .hff-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 24px 32px;

        .form-control-wrapper{
          width: 100%;
          margin-bottom: 16px;
          &:last-child{
            margin-bottom: 0;
          }
          &.middle{
            width: 422px;
          }
          &.short{
            width: 204px;
          }
          .form-label{
            font-weight: 500;
            margin-bottom: 12px;
          }
          .form-control{
            padding: 15px 16px;
            border: none;
            background-color: #F4F7FB;
            line-height: 22.4px;
          }
        }
      }
      .hff-footer{
        justify-content: center;
        align-items: center;
        height: 80px;
        gap: 16px;
      }
    }
  }
  .modal-xl {
    --bs-modal-width: 706px;
  }
}
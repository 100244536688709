.tab-menu-temp {
  display: flex;
  flex-direction: row;
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  height: 64px;

  .tab-menu-item-temp {
    width: 200px;
    height: 100%;
    display: flex;
    justify-content: center;
    color: #0016368A;
    align-items: center;
    line-height: 19.2px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    &.active {
      color: #1665A7;
      border-bottom: 3px solid #1665A7;
    }
  }
}

.main-layout {
  min-width: 1366px;
  min-height: 700px;
  .app-container {
    display: flex;
    flex-direction: row;
    .app-left-column {
      position: absolute;
      display: flex;
      flex-direction: column;
      height: 100vh;
      width: 64px;
      min-width: 64px;
      transition: 0.3s;
      border-right: 1px solid #eee;
      z-index: 2;
      background: #1665A7;
      .toggle-button-wrapper {
        width: 100%;
        min-width: 64px;
        height: 64px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: end;
        box-shadow: inset 0 -1px 0 0px #ffffff;
        padding: 0 22px;
        .toggle-button {
          width: 20px;
          height: 32px;
          fill: #ffffff;
          color: #ffffff;
        }
        &:hover {}
      }
      .app-menu {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        .bd-links {
          width: 100%;
          flex: 1;
          .nav {
            width: 100%;
            .bd-toc-item {
              width: 100%;
              .menu-link {
                padding-left: 16px;
                width: 100%;
                height: 64px;
                white-space: nowrap;
                display: flex;
                flex-direction: row;
                align-items: center;
                text-align: center;
                justify-content: start;
                text-decoration: none;
                font-weight: 500;
                color: #6c757d;
                .menu-item-icon {
                  width: 32px;
                  min-width: 32px;
                  height: 32px;
                  min-height: 32px;
                  color: #ffffff;
                  fill: #ffffff;
                }
                .nav-link-text {
                  color: #fff;
                  text-align: left;
                }
                .nested-arrow {
                  display: none;
                }
                &.selected {
                  background: #91BADC;
                }
                &:hover {
                  background: #91BADC;
                }
              }
              &:last-child {
                .sub-nav-group {
                  .bd-toc-item:last-child {
                    padding-bottom: 64px;
                  }
                }
              }
            }
          }
        }
        .nav-link-text{
          transition: 0.3s;
          opacity: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          //white-space: nowrap;
          white-space: normal;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          color: #000000;
          margin-left: 0;
        }
        .version-block {
          padding: 12px 16px;
          flex-direction: row;
          display: none;
          color: #fff;
          opacity: 0.4;
          justify-content: flex-end;
          .version-text {
            margin-right: 8px;
          }
          .version-number {
          }
        }
        .menu-hr {
          height: 1px;
          width: 100%;
          background: #fff;
        }
      }
      &.active {
        .version-block {
          display: flex;
        }
        min-width: 256px;
        transition: 0.3s;
        .nav-link-text{
          transition: 0.3s;
          opacity: 1;
          transition-delay: 0.1s;
          margin-left: 10px;
        }
        .sub-nav-group {
          transition: 0.6s;
          max-height: 500px;
          overflow: visible;
          .menu-link {
            transition: opacity 0.6s;
            opacity: 1;
            transition-delay: 0.2s;
          }
        }
      }
    }
    .app-content-wrapper{
      position: relative;
      width: calc( 100% - 64px );
      height: 100vh;
      left: 64px;
      overflow: visible;
      max-height: 100vh;
      margin-left: 0;
      margin-right: 0;
      z-index: 1;
      .app-content {
        overflow: auto;
        max-height: 100vh;
        height: 100%;
        margin-left: 0;
        margin-right: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        .app-bar {
          width: 100%;
          height: 64px;
          background: #fff;
          display: flex;
          flex-shrink: 0;
          flex-direction: row;
          align-items: center;
          z-index: 5;
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.13);
        }
        .app-content-children {
          position: relative;
          flex: 1;
          .content {
            position: absolute;
            top: 0;
            padding: 32px 32px 32px 32px;
            width: 100%;
            height: 100%;
            overflow: auto;
          }
          .main-page {
            width: 100%;
          }
        }
      }
    }
  }
}

.logo-block {
  display: flex;
  flex-direction: row;
  .logo {
    //width: 30px;
    height: 34px;
    border-radius: 30px;
    margin-left: 32px;
    .header-logo {
      height: 34px;
    }
  }
  .logo-name {
    margin-left: 32px;
    display: flex;
    align-items: center;
    line-height: 1em;
    // margin-left: 8px;
    font-size: 18px;
    font-style: normal;
    font-weight: 682;
  }
}

.mini-profile-anchor {
  .mini-profile-wrapper {
    display: flex;
    gap: 25px;
    flex-direction: row;
    align-items: center;
    &:after {
      display: none;
    }
    .mini-profile-notifications {
      margin-right: 32px;
      fill: #B3B9C3;
      color: #B3B9C3;
      cursor: pointer;
    }
    .mini-profile-update-period{
      cursor: pointer;
      color: #758192;
      &:hover {
        color: #1665A7;
      }
    }
    .mini-profile-separator {
      height: 38px;
      width: 1px;
      background-color: #D4D9E3;
    }
    .mini-profile-data {
      color: #213450;
      text-align: right;
      margin-right: 8px;
      .click-wrapper{
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px
      }
      .profile-data-name {
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;
      }
    }
    .mini-profile-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 38px;
      height: 38px;
      border-radius: 38px;
      background-color: #D9D9D9;
      margin-right: 32px;
      overflow: hidden;
      img {
        max-height: 100%;
        //max-width: 100%;
      }
    }
  }
}

.frame {
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
}

.auto-reload {
  margin-left: auto;
  .auto-reload-block {
    height: 38px;
    border-right: 1px solid #E2E8F1;
    padding: 0 16px;
    display: flex;
    align-items: center;
    margin-top: 4px;
    .auto-reload-icon {
      width: 30px;
      height: 30px;
      cursor: pointer;
      color: #758192;
      &:hover {
        color: #1665A7;
      }
    }
  }
}

@media (max-width: 1799px) {
  .main-layout {
    min-width: 700px;
    min-height: 600px;
    .app-container {
      .app-left-column {
        width: 48px;
        min-width: 48px;
        .toggle-button-wrapper {
          min-width: 48px;
          height: 48px;
          padding: 0 15px;
          .toggle-button {
            width: 17px;
            height: 28px;
          }
        }
        .app-menu {
          .bd-links {
            .nav {
              .bd-toc-item {
                .menu-link {
                  height: 48px;
                  padding: 12px 12px;
                }
              }
            }
          }
          .nav-link-text{
            font-size: 14px;
          }
        }
        &.active {
          min-width: 267px;
        }
      }
      .app-content-wrapper {
        width: calc(100% - 48px);
        left: 48px;
        .app-content {
          .app-bar {
            height: 48px;
          }
          .app-content-children {
            .content {
              padding: 24px 0 24px 24px;
            }
          }
        }
      }
    }
  }

  .mini-profile-anchor {
    .mini-profile-wrapper {
      .mini-profile-separator {
        height: 30px;
      }
      .mini-profile-image {
        width: 30px;
        height: 30px;
      }
      .mini-profile-data {
        .profile-data-name {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .logo-block {
    .logo {
      height: 30px;
      .header-logo {
        height: 30px;
      }
    }
    .logo-name {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.mobile-layout {
  .main-layout {
    min-width: 320px;
    min-height: 320px;
    .app-left-column {
      top: 38px;
      left: -48px;
      &.active{
        display: block;
        left: 0px;
      }
    }
    .app-container {
      display: flex;
      flex-direction: row;
      .app-content-wrapper{
        width: 100%;
        left: 0;
        .app-content {
          .app-bar {
            background: #1665A7;
            height: 38px;
            min-height: 38px;
          }
        }
      }
    }

    .mini-profile-anchor {
      .mini-profile-wrapper {
        .mini-profile-separator {
          display: none;
        }
        .mini-profile-data {
          display: none;
        }
        .mini-profile-image {
          width: 30px;
          height: 30px;
          border-radius: 38px;
          margin-right: 16px;
        }
      }
    }
    .logo-block {
      align-items: center;
      .toggle-button-wrapper{
        color:white;
        margin-left: 10px;
      }
      .logo {
        height: 30px;
        margin-left: 16px;
        .header-logo {
          height: 30px;
        }
      }
      .logo-name {
        color: #FFF;
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;
      }
    }
  }
}


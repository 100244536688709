.form-block-label {
  display: flex;
  flex-direction: row;
  .form-block-label-text {
    color: #213450;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
  }
  .form-block-label-button {
    margin-left: auto;
    color: #1665A7;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
    cursor: pointer;
    &:hover {
      color: #085290;
    }
  }
}
.form-block-presets {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  .presets-button {
    margin: 0 6px;
    flex: 1 1 auto;
    border-radius: 4px;
    border: 1px solid #A6AEB9;
    color: #758192;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;

    &.active {
      border: 1px solid #085290;
      background: #EAF5FF;
      color: #085290;
    }

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.bordered-checkbox,
.check-box-with-label {
  padding: 12px 0;
  margin-bottom: 0;
  .form-check-input {
    width: 18px;
    height: 18px;
    margin-left: 0;
    border: none;
    background-color: transparent;
    border-radius: 0;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNC4zMzQgMC43NUg1LjY2NUMyLjY0NCAwLjc1IDAuNzUgMi44ODkgMC43NSA1LjkxNlYxNC4wODRDMC43NSAxNy4xMTEgMi42MzUgMTkuMjUgNS42NjUgMTkuMjVIMTQuMzMzQzE3LjM2NCAxOS4yNSAxOS4yNSAxNy4xMTEgMTkuMjUgMTQuMDg0VjUuOTE2QzE5LjI1IDIuODg5IDE3LjM2NCAwLjc1IDE0LjMzNCAwLjc1WiIgc3Ryb2tlPSIjNzU4MTkyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
    &:checked[type=checkbox] {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01LjY3IDBIMTQuMzRDMTcuNzMgMCAyMCAyLjM4IDIwIDUuOTJWMTQuMDkxQzIwIDE3LjYyIDE3LjczIDIwIDE0LjM0IDIwSDUuNjdDMi4yOCAyMCAwIDE3LjYyIDAgMTQuMDkxVjUuOTJDMCAyLjM4IDIuMjggMCA1LjY3IDBaTTkuNDMgMTIuOTlMMTQuMTggOC4yNEMxNC41MiA3LjkgMTQuNTIgNy4zNSAxNC4xOCA3QzEzLjg0IDYuNjYgMTMuMjggNi42NiAxMi45NCA3TDguODEgMTEuMTNMNy4wNiA5LjM4QzYuNzIgOS4wNCA2LjE2IDkuMDQgNS44MiA5LjM4QzUuNDggOS43MiA1LjQ4IDEwLjI3IDUuODIgMTAuNjJMOC4yIDEyLjk5QzguMzcgMTMuMTYgOC41OSAxMy4yNCA4LjgxIDEzLjI0QzkuMDQgMTMuMjQgOS4yNiAxMy4xNiA5LjQzIDEyLjk5WiIgZmlsbD0iIzE2NjVBNyIvPgo8L3N2Zz4K");
    }
    &.some-child {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01IDBDMi4yMzg1OCAwIDAgMi4yMzg1OCAwIDVWMTVDMCAxNy43NjE0IDIuMjM4NTggMjAgNSAyMEgxNUMxNy43NjE0IDIwIDIwIDE3Ljc2MTQgMjAgMTVWNUMyMCAyLjIzODU4IDE3Ljc2MTQgMCAxNSAwSDVaTTYgOUM1LjQ0NzcyIDkgNSA5LjQ0NzcxIDUgMTBDNSAxMC41NTIzIDUuNDQ3NzIgMTEgNiAxMUgxNEMxNC41NTIzIDExIDE1IDEwLjU1MjMgMTUgMTBDMTUgOS40NDc3MSAxNC41NTIzIDkgMTQgOUg2WiIgZmlsbD0iIzE2NjVBNyIvPgo8L3N2Zz4K");
    }
    &:focus {
      box-shadow: none;
    }
  }
  .form-check-label {
    margin-left: 16px;
    cursor: pointer;
  }
}

.check-box-accordion {
  width: 100%;
  border: 1px solid #B3B9C3;
  border-radius: 4px;
  background: #fff;
  padding: 6px 0;
  .cbl-accordion-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #758192;
    padding: 0.375rem 16px 0.375rem 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    .cbl-checkbox {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .form-check-input {
        padding: 0;
        margin: 0;
        line-height: 1;
      }
    }
    .accordion-label-text {
      margin-left: 16px;
      flex: 1;
      line-height: 1;
      padding-left: 5px;
    }
    .accordion-icons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      width: auto;
      height: 24px;
      .close {
        height: 12px;
        width: 12px;
      }
    }
    .accordion-icons-arrow {
      margin-left: auto;
      text-align: right;
    }
    .clear-check-box {
      margin-left: 16px;
      margin-right: 16px;
      fill: #758192;
      opacity: 0.7;
    }
    .check-box-accordion-icon {
      //margin-left: auto;
    }
  }
  .check-box-accordion-content {
    display: none;
  }
  &.open {
    border: 1px solid #1665A7;
    box-shadow: 0 0 0 1px #1665A7, 0 10px 30px 0 rgba(0, 0, 0, 0.15);
    .cbl-accordion-label {
      .check-box-accordion-icon {
        transform: rotate(180deg);
      }
    }
    .check-box-accordion-content {
      display: block;
      .form-block-presets {
        margin-top: 0;
        padding: 12px 16px;
      }
      .check-box-with-label {
        padding: 12px 16px;
        line-height: 1;
      }
      .checked-checkbox {
        background: #EAF5FF;
      }
      .switch-control-wrapper {
        margin-top: 0!important;
        .form-switch {
          padding: 12px 16px;
        }
      }
    }
  }
}

.radio-list {
  .cbl-accordion-label {
    padding: 6px 16px;
    position: relative;
    .accordion-label-text {
      top:0;
      left: 12px;
      font-size: 16px;
      transition: .3s;
      margin-left: 0;
    }
  }
  .check-box-accordion-content {
    .radio-control-list {
      margin-top: 0px!important;
      .form-label {
        display: none;
      }
      .radio-btn-group {
        margin-top: 6px;
        .form-check {
          .form-check-label {
            padding-left: 12px;
            .form-check-input-icon {
              display: none;
            }
          }
          &.checked {
            background: #EAF5FF;
          }
        }
      }
    }
  }
  &.selected {
    .accordion-label-text {
      position: absolute;
      top: -12px;
      font-size: 12px;
      background: #fff;
      padding: 0 4px 0 4px;
      border-radius: 10px;
    }
  }
}

.mobile-layout {
  .check-box-with-label {
    .form-check-input {
      margin: 0;
    }
  }
  .form-check-label {
    font-size: 16px;
    line-height: 16px;
    padding-left: 12px;
  }
  .form-check-label {
    margin-left: 0;
  }
  .check-box-accordion{
    .cbl-accordion-label {
      padding: 6px 12px;
      .accordion-label-text {
        margin-left: 0;
      }
    }
  }
}
.check-box-accordion {
  .cbl-accordion-label {
   padding-left: 6px;
  } 
  &.open {
    .check-box-accordion-content {
      .check-box-with-label {
        padding-left: 12px;
      }
      .form-check-label{
        padding-left: 6px;
      }
  }
  }
}

@media (max-width: 1799px) {
  .form-check-label {
    font-size: 13px;
    line-height: 13px;
  }
  .form-block-label {
    .form-block-label-text {
      font-size: 14px;
    }
    .form-block-label-button {
      font-size: 14px;
    }
  }
  .form-block-presets {
    .btn {
      font-size: 14px;
    }
  }
}



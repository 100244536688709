.hosts-page{

  .hosts-wrapper {
    display: flex;
    flex-direction: column;
    gap: 22px;
    padding: 28px 32px 28px 32px;
  
    .host-status{
      display: flex;
      justify-content: space-between;
      min-height: 872px;
  
      .host-status-info{
        display: flex;
        flex-shrink: 0;
        width: 46.6%;
        background-color: white;
        box-shadow: 0px 2px 10px 0px #00000021;
        border-radius: 4px;
        padding: 24px;

      .host-status-box{
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 24px;
        .host-status-box-panel{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .host-status-box-control{
            display: flex;
            gap: 16px;
            .control-button{
              display: flex;
              font-weight: 500;
              cursor: pointer;
              color:#1665A7;
              gap: 9px;
              width: 132px;
              justify-content: center;
              align-items: center;
              height: 32px;
              border-radius: 4px;
              border: 2px solid #1665A7;
              &.command{
                background-color: #1665A7;
                color: white;
              }
            }
            .control-button:hover{
              background-color: #EAF5FF;
              &.command{
                background-color: #085290;
              }
            }
          }
        }
        .host-status-items{
          display: flex;
          width: 100%;
          height: 100%;
          align-content: start;
          flex-wrap: wrap;
          gap: 24px;
          justify-content: space-between;
          .host-status-item{
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: space-between;;
            border: 1px solid #E2E8F1;
            width: calc((100%/3) - 16px);
            padding: 16px;
            min-height: 154px;
            border-radius: 4px;
            max-height: 235px;
            &.visual{
              justify-content: center;
              min-height: 120px;
              padding: 0;
            }
            .item-content{
              display: flex;
              flex-direction: column;
              gap: 8px;
              .item-title{
                display: flex;
                justify-content: space-between;
                align-items: start;
                .item-label{
                  display: flex;
                  align-items: center;
                  font-size: 16px;
                  font-weight: 500;
                  margin-right: 2px;
                  width: 100%;
                }
                .item-equipment-container{
                  .equipments-group-item {
                    position: relative;
                    .equipments-group-item-status {
                      position: absolute;
                      bottom: -1px;
                      right: 2px;
                    }
                  }
                }
              }
              .item-description{
                font-size: 16px;
                font-weight: 400;
                line-height: 20.8px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
            }
            .item-control-panel{
              display: flex;
              gap: 16px;
              width: 100%;
              justify-content: center;
              border-top: 1px solid #E2E8F1;
              margin-top: 4px;
              padding-top: 12px;
              .item-control-button{
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0px 0px 10px 0px #00000040;
                height: 36px;
                width: 36px;
                border-radius: 4px;
                cursor: pointer;
                color: #1665A7;
                background-color: white;
              }
              .item-control-button:hover{
                color: white;
                background-color: #1665A7;
              }
            }
          }
          .container{
            justify-content: start;
            padding: 0;
          }
        }
      }

      .setting-host-status-history-portal {
        .dropdown-menu{
          right: -24px;
          top: 198%;
          box-shadow: 0px 10px 30px 0px #00000026;
        }
      }

      .host{
        font-size: 18px;
        font-weight: 500;
      }
      }
  
      .host-status-event{
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 24px;
        width: 52.9%;
        border-radius: 4px;
        background-color: white;
        box-shadow: 0px 2px 10px 0px #00000021;
  
        .setting-table{
          border: 1px solid #E2E8F1;
          border-radius: 4px;
          .frame{
            box-shadow: none;
          }
        }
  
        .host-status-event-panel{
          display: flex;
          justify-content: space-between;
          .host-status-title, .host-status-show-table{
            font-size: 18px;
            font-weight: 500;
          }
          .host-status-show-table{
            color: #1665A7;
            cursor: pointer;
          }
        }
        .equipments-group-item-status{
          display: flex;
          width: 100%;
          justify-content: center;
        }
  
        .host-status-event-grid {
          .column-0 {
            width: 56px;
            cursor: pointer;
            position: relative;
          }
          .column-1 {
            width: 10.3%;
          }
          .column-2 {
            width: 12.2%;
          }
          .column-3 {
            width: 7.8%;
          }
          .column-4 {
            width: 7.8%;
          }
          .column-5 {
            width: 7.8%;
          }
          .column-6 {
            width: 4.6%;
          }
          .column-7 {
            width: auto;
          }
          .settings-column {
            width: 56px;
            .more-vert{
              color: #A6AEB9;
            }
            &:hover{
              color: #1665a7;
              cursor: pointer;
            }
          }
          .checkbox-column {
            width: 56px;
          }
        }
        .table-header-cell{
          padding: 18.5px 16px;
        } 
        .data-table-cell{
          height: 60px;
        }
      }
    }
  
    .host-table {
      display: flex;
      flex-direction: column;
  
      .grid-pagination{
        position: relative;
        justify-content: center;
        .pagination-info{
          position: absolute;
          left: 0;
        }
      }
      
      .frame {
        display: flex;
        height: auto;
        overflow: hidden;
      }
  
      .host-grid-main {
        .column-0 {
          width: 14.6%;
          max-width: 262px;
        }
        .column-1 {
          max-width: 376px;
          width: 20.2%;
        }
        .column-2 {
          width: 7.3%;
          max-width: 137px;
        }
        .column-3 {
          width: 8.7%;
          max-width: 162px;
        }
        .column-4 {
          width: auto;
          max-width: 578px;
        }
        .column-5 {
          width: 11.9%;
          max-width: 221px;
        }
        .settings-column {
          width: 56px;
          .more-vert{
            color: #A6AEB9;
          }
          &:hover{
            color: #1665a7;
            cursor: pointer;
          }
        }
        .checkbox-column {
          width: 56px;
        }
      }
      
    }
  
    .hosts-inputs-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .hosts-info, .hosts-inputs {
        display: flex;
        align-items: center;
        gap: 16px;
  
        input {
          height: 40px;
        }
  
        .page-header {
          .header-text {
            font-size: 24px;
            font-weight: 700;
          }
        }
  
      }
  
      .hosts-info {
        gap: 32px;
  
        &.variant {
          flex-direction: column;
          align-items: start;
          gap: 12px;
          font-size: 14px;
        }
      }
    }
  }
}

.hosts-more-wrapper {
  padding: 6px 0;
  min-width: 360px;
  .hosts-more-button {
    text-align: left;
    padding: 15px;
    border-radius: 0;
    color: #213450;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #b3b9c3;
    align-items: center;
    &:last-child {
      border-bottom: none;
    }
    .hosts-more-button-icon {
      width: 24px;
      margin-right: 6px;
      text-align: center;
    }
    .button-icons {
      margin: 0 auto;
      color: #1665a7;
    }
  }
}

.wsh-info-tooltip {
  padding: 24px;
  min-width: 300px;
  &.host-problems {
    min-width: 450px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    .problems-scroll-wrapper {
      height: 100%;
      flex: 1;
    }
  }
  .wsh-info-title {
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    padding: 0 16px;
    .close {
      margin-left: auto;
    }
  }

  .wsh-info-table {
    width: 100%;
    margin-top: 12px;
    .wsh-info-table-row {
      border-top: 1px solid #E2E8F1;
      .wsh-info-table-cell {
        height: 56px;
        padding: 16px;
        vertical-align: middle;
        text-align: left;
        min-width: 164px;
        &.wsh-key {
          font-size: 16px;
          font-weight: 500;
        }
        &.wsh-value {
          font-size: 16px;
          font-weight: 400;
        }
        &.wsh-date {
          font-size: 16px;
          font-weight: 400;
          width: 145px;
          min-width: 145px;
          text-align: center;
        }
        &.wsh-message {
          font-size: 16px;
          font-weight: 400;
        }

        &:first-child {
          border-right: 1px solid #E2E8F1;
        }
      }
      &:last-child {
        border-bottom: 1px solid #E2E8F1;
      }
    }
  }

  .mpf-more-actions {
    width: 100% !important;
    bottom: 0 !important;
    top: 0!important;
    left: 0!important;
    right: 0!important;
    background: rgba(33, 52, 80, 0.35);
    .hosts-more-tooltip {
      top: auto;
      bottom: 0;
      width: 100%;
      max-height: initial!important;
      border-radius: 4px 4px 0 0;
      .hosts-tooltip-popup {
        .hosts-more-wrapper {
          .wsh-info-title {
            display: flex;
            flex-direction: row;
            padding: 16px;
            font-size: 16px;
            font-weight: 500;
            line-height: 120%; /* 19.2px */
            .close {
              margin-left: auto;
              margin-right: 4px;
            }
          }
          .hosts-more-button {
            padding: 12px 16px;
            font-size: 16px;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
          }
        }
      }
    }
  }
}

.host-status-event-grid-popup{
  .modal-dialog {
    z-index: 1051;
    .modal-content {
      height: 1002px;
      border-radius: 4px;
      border: 0;
      .hff-title {
        display: flex;
        flex-direction: row;
        padding: 28px 32px 20px 28px;
        box-shadow: 0px 2px 10px 0px #00000021;
        border: none;
        .form-control{
          font-size: 16px;
          font-weight: 500;
          line-height: 19.2px;
          color: #213450;
          .input-group-text{
            padding-right: 8px;
            padding-left: 16px;
          }
        }
        .hff-title-text {
          color: #213450;
          line-height: 32px;
          margin-right: auto;
        }
        .header-buttons{
          padding: 0;
        }
        .hff-close-wrapper {
          padding: 0 5px;
          .hff-close {
            color: #758192;
            cursor: pointer;
            &:hover {
              color: #1665A7;
            }
          }
        }
      }
      .hff-content {
        padding: 24px 32px;
        .history-status-formatter{
          display: flex;
          width: 100%;
          justify-content: center;
          .history-status-formatter-info{
            display: flex;
            width: 108px;
            height: 24px;
            border-radius: 16px;
            background-color: #6AD387;
            color: white;
            font-weight: 682;
            font-size: 14px;
            justify-content: center;
            align-items: center;
            &.isError{
              background-color: #E96363;
            }
            &.isWait{
              background-color: #FFBC59;
            }
          }
        }
        .table-header-cell{
          padding: 18.5px 16px;
        } 
        .setting-table{
          border: 1px solid #E2E8F1;
          border-bottom: none;
          border-radius: 4px;
          .frame{
            box-shadow: none;
          }
        }
        .data-table-cell{
          height: 60px;
        }
        .column-0{
          width: 180px;
        }
        .column-1{
          width: 510px;
        }
        .column-2{
          width: 154px;
        }
      }
      .hff-footer {
        padding: 28px 27px;
        justify-content: space-between;
        .grid-pagination{
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 0;
        }
      }
    }
  }
  .modal-xl {
    --bs-modal-width: 908px;
  }
}

.host-popup{
  .modal-dialog {
    z-index: 1051;

    .header-buttons{
      justify-content: space-between;
    }

    .hff-content{
      display: flex;
      flex-direction: column;
      gap: 32px;

      .input-form{
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
      }
      .form-description{
        display: flex;
        flex-direction: column;
        gap: 16px;
        .form-description-title{
          font-size: 16px;
          color: #001636DE;
          font-weight: 682;
        }
      }
      .form-equpment{
        display: flex;
        flex-direction: column;
        gap: 16px;

        .form-equpment-title{
          font-size: 16px;
          color: #001636DE;
          font-weight: 682;
        }
        .equipments-group{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-start;

          .equipments-group-items{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100px;
            .equipments-group-items-title{
              text-align: center;
            }
          }
        }
      }
      .form-control-wrapper{
        width: 100%;
        margin-bottom: 16px;
        &:last-child{
          margin-bottom: 0;
          .form-control{
            padding: 12px 16px;
          }
        }
        &.middle{
          width: 100%;
        }
        &.short{
          width: 302px;
        }
        .form-label{
          font-weight: 500;
          margin-bottom: 12px;
        }
        .form-control{
          padding: 15px 16px;
          border: none;
          background-color: #F4F7FB;
          line-height: 22px;
        }
        .form-label{
          line-height: 19px;
        }
      }
    }

    .hff-footer{
      justify-content: center;
    }
  }
  .modal-xl {
    --bs-modal-width: 668px;
  }
}
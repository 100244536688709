.not-found-page-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background: #D1E9FF;
  .not-found-page {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 600px;
    min-width: 800px;
    overflow: hidden;
    .parking-meter {
      width: 10.78%;
      background-size: contain;
      background-position: 0 100%;
      bottom: 3.1%;
      height: 65%;
      top: auto;
      z-index: 2;
      animation-name: parking-meter-animation-2;
    }

    .camera-1 {
      background-position: 100% 0;
      background-size: contain;
      top: 12.1%;
      height: 14.7%;
      width: 30%;
      z-index: 2;
    }

    .car {
      position: absolute;
      bottom: 4%;
      right: 0;
      width: 30%;
      height: 38%;
      background: url("../../img/car.svg") no-repeat 100% 100%;
      background-size: contain;
      animation-duration: 1s;
      animation-name: car-animation;
      z-index: 2;
    }

    .cloud-1 {
      position: absolute;
      top: 6.3%;
      right: 26.6%;
      width: 9.6%;
      height: 5.3%;
      background: url("../../img/cloud-1.svg") no-repeat 0 0;
      background-size: contain;
      animation-duration: 1s;
      animation-name: cloud-animation;
      z-index: 2;
    }

    .cloud-2 {
      position: absolute;
      top: 35.7%;
      right: 10%;
      width: 23.6%;
      height: 13.8%;
      background: url("../../img/cloud-2.svg") no-repeat 0 0;
      background-size: contain;
      animation-duration: 1s;
      animation-name: cloud-animation;
      z-index: 2;
    }

    .cloud-3 {
      position: absolute;
      top: 12%;
      left: 15%;
      width: 18.6%;
      height: 12.6%;
      background: url("../../img/cloud-3.svg") no-repeat 0 0;
      background-size: contain;
      animation-duration: 1s;
      animation-name: cloud-animation;
      z-index: 2;
    }

    .cloud-4 {
      position: absolute;
      top: 43.6%;
      left: 12.5%;
      width: 11.6%;
      height: 6.7%;
      background: url("../../img/cloud-4.svg") no-repeat 0 0;
      background-size: contain;
      animation-duration: 1s;
      animation-name: cloud-animation;
      z-index: 2;
    }

    .ground {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 20.74%;
      background: #758192;
      z-index: 0;
    }
    .not-found-block-wrapper {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: start;
      z-index: 3;
      .not-found-block-border {
        border-radius: 4px;
        border: 10px solid #FFF;
        margin-top: 7.6%;
        .not-found-block {
          border-radius: 4px;
          background: #91BADC;
          padding: 64px;
          .text-1 {
            width: 100%;
            text-align: center;
            font-size: 320px;
            font-weight: 700;
            color: #FFF;
            line-height: 81%;
          }
          .text-2 {
            width: 100%;
            text-align: center;
            font-size: 52px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            color: #FFF;
          }
          .text-3 {
            width: 100%;
            text-align: center;
            margin-top: 24px;
            .btn {
              font-size: 18px;
              padding: 16px 32px;
            }
          }
        }
      }
    }
  }
}


@keyframes car-animation {
  from {
    right: -100%;
  }

  to {
    right: 0;
  }
}

@keyframes parking-meter-animation-2 {
  from {
    left: -100%;
  }

  to {
    left: 0;
  }
}

@keyframes cloud-animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


@media (max-width: 1799px) {
  .not-found-page-wrapper {
    .not-found-page {
      .not-found-block-wrapper {
        .not-found-block-border {
          .not-found-block {
            padding: 48px;
            .text-1 {
              font-size: 200px;
            }
            .text-2 {
              font-size: 32px;
              font-weight: 500;
            }
            .text-3 {
              .btn.big {
                padding: 8px 32px;
              }
            }
          }
        }
      }
    }
  }
}

.mobile-layout {
  .not-found-page-wrapper {
    .not-found-page {
      min-height: 100%;
      min-width: 100%;
      .parking-meter,
      .camera-1,
      .car,
      .cloud-1,
      .cloud-2,
      .cloud-3,
      .cloud-4,
      .ground {
        display: none;
      }
      .not-found-block-wrapper {
        .not-found-block-border {
          border: none;
          margin: 0;
          width: 100%;
          height: 100%;
          border-radius: 0;
          .not-found-block {
            width: 100%;
            height: 100%;
            border-radius: 0;
            padding: 16px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            min-height: 450px;
            .text-1 {
              text-align: center;
              font-size: 160px;
              font-weight: 682;
              line-height: 130%;
            }
            .text-2 {
              font-size: 24px;
              font-weight: 500;
              line-height: 130%;
            }
            .text-3 {
              position: absolute;
              bottom: 24px;

            }
          }
        }
      }
    }
  }
}

.form-control-wrapper {
  &.default {
    .form-control {
      padding: 8px 12px;
      border-radius: 4px;
      transition: none;
      &:focus {
        box-shadow: 0 0 0 1px #1665A7, 0 2px 10px 0 rgba(0, 0, 0, 0.13);
        border-color: #1665A7;
      }
    }
    .input-group {
      border: 1px solid #ced4da;
      border-radius: 4px;
      .input-group-text {
        background: #fff;
        box-shadow: none;
        border: none;
        padding: 8px 8px 8px 12px;
      }
      .form-control {
        border: none;
      }
      &:focus-within {
        box-shadow: 0 0 0 1px #1665A7, 0 2px 10px 0 rgba(0, 0, 0, 0.13);
        border: 1px solid transparent;
        .form-control {
          border: none;
          box-shadow: none;
        }
      }
      &.with-before {
        .form-control {
          padding-left: 0;
        }
      }
      &.with-after {
        .form-control {
          padding-right: 0;
        }
      }
    }
  }
  &.no-border {
    .form-control {
      border: none;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.13);
    }
    .input-group {
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.13);
      border: none;
      .form-control {
        border: none;
        box-shadow: none;
      }
      &:focus-within {
        border: none;
      }
    }
  }
}

.form-control-wrapper.material {
  .input-block {
    vertical-align: top;
    position: relative;
    width: 100%;
    border: 1px solid #B3B9C3;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #fff;
    &:focus,
    &:focus-within {
      border: 1px solid #1665A7;
      box-shadow: 0 0 0 1px #1665A7, 0 2px 10px 0 rgba(0, 0, 0, 0.13);
      .form-input-label {
        top: -10px;
        font-size: 12px;
        background: #fff;
      }
    }
    .form-control {
      width: 100%;
      height: 56px;
      border: none;
      box-shadow: none;
      border-radius: 3px;
      font-size: 16px;
    }
    .form-input-label {
      position: absolute;
      pointer-events: none;
      left: 16px;
      top: -10px;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      transition: .3s;
      padding: 0 4px 0 4px;
      border-radius: 10px;
      background: #fff;
      color: #758192;
    }
    .form-control[value=""]~.form-input-label {
      top: 16px;
      font-size: 16px;
      background: transparent;
    }
    .form-control:-webkit-autofill~.form-input-label,
    .form-control:focus~.form-input-label {
      top: -10px;
      font-size: 12px;
      background: #fff;
    }
    .input-before-block {
      margin-left: 16px;
    }
    .input-after-block {
      margin-right: 10px;
    }
  }
  &.small {
    .input-block {
      .form-control {
        height: 38px;
      }
      .form-control[value=""]~.form-input-label {
        top: 8px;
      }
      .form-control:-webkit-autofill~.form-input-label,
      .form-control:focus~.form-input-label {
        top: -10px;
      }
    }
  }
  &.no-border {
    .input-block {
      border: none;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 100000s ease-in-out 0s;
}

.password-icon {
  cursor: pointer;
  fill: #758192;
}
textarea {
  &.resize-none{
    resize: none;
  }
}

.invalid-feedback {
  font-size: 12px;
  line-height: 12px;
  color: #DE0F0F;
  margin-top: 3px;
}

.mobile-layout {
  .form-control-wrapper.material {
    .input-block {
      &:focus,
      &:focus-within {
        .form-input-label {
          top: -10px;
        }
      }
      .form-control {
        height: 48px;
        padding-left: 12px; 
      }
      .form-input-label {
        top: -10px;
        left: 12px;
        border-radius: 10px;
      }
      .form-control[value=""]~.form-input-label {
        top: 12px;
        left: 12px;
      }
      .form-control:-webkit-autofill~.form-input-label,
      .form-control:focus~.form-input-label {
        top: -10px;
      }
    }
  }
}

@media (max-width: 1799px) {
  .form-control-wrapper {
    &.default {
      .form-control {
        padding: 6px 12px;
      }
    }
  }
  .form-control {
    font-size: 13px;
  }
}

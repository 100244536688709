.page-header {
  display: flex;
  flex-direction: row;
  .header-text {
    line-height: 134%;
    color: #213450;
    font-size: 24px;
    font-weight: 700;
  }
  .header-buttons {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .header-info {
      margin-right: auto;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #2E3338;
      padding: 0 0 0 16px;
    }

  }
}

@media (max-width: 1799px) {
  .page-header {
    .header-text {
      font-size: 18px;
      font-weight: 700;
      line-height: 140%;
    }
  }
}

.spinner-wrapper {
  &.locked{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(33, 52, 80,0.15);
    z-index: 9999;
  }
  &.global {
    z-index: 99700;
  }
  &.hide-screen {
    background: #ffffff;
    z-index: 997000;
  }
  .spinner-border {
    color: #1665A7;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0,0,0,0);
      white-space: nowrap;
      border: 0;
    }
  }
}
@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.header-loader {
  display: flex;
  flex-direction: row;
  .header-loader-text {
    text-wrap: nowrap;
  }
  .spinner-wrapper {
    margin-left: 16px;
    padding: 8px 0;
    &.locked {
      position: relative;
      background: transparent;
      .spinner-border {
        width: 1rem;
        height: 1rem;
        border-width: 0.1em;
      }
    }
  }
}
.role-page {
  display: flex;
  flex-direction: column;
  .panel{
    width: 100%;
    justify-content: start;
  }
  .role-page-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    min-width: 1100px;
    .only-admin {
      flex: 1;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      color: #A6AEB9;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 682;
    }
    .permission-block,
    .role-menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      .tab-menu {
        padding: 0;
        height: 100%;
        background: transparent;
        box-shadow: none;
        border-radius: 0;
        min-height: 64px;
        .tab-menu-item {
          border-radius: 0;
          padding: 0;
          color: #758192;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 200px;
          &.active {
            color: #1665A7;
            background: transparent;
            &:after {
              content: " ";
              position: absolute;
              width: 100%;
              height: 3px;
              border-radius: 3px;
              bottom: 0;
              left: 0;
              background: #1665A7;
            }
          }
        }
      }
      .button-block {
        padding:  0 24px;
        .btn {
          min-width: 110px;
          margin-left: 16px;
        }
      }
      .before-save-timer {
        display: none;
        margin-left: auto;
        margin-right: 16px;
        position: relative;
        width: 32px;
        height: 32px;
        .spinner-wrapper {
          background: transparent;
        }
        &.animation-start{
          display: block;
        }
      }
    }
    .role-list-wrapper {
      margin-top: 6px;
      flex: 1;
      gap: 8px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .users-block {
        height: 100%;
        width: 25.4%;
        .frame {
          height: 100%;
        }
      }
      .form-control-wrapper .custom-select .input-group .input-group-text 
      {
        box-shadow: none;
      }
      .group-block {
        height: 100%;
        width: 51.33%;
        gap: 8px;
        display: flex;
        flex-direction: row;
        .group-users,.group-names{
          flex: 1 0;
        } 
        .group-names {
          height: 100%;
          .frame {
            height: 100%;
          }
        }
        .group-users {
          height: 100%;
          .frame {
            height: 100%;
          }
        }
      }
      .permission-block {
        display: flex;
        height: 100%;
        flex: 1 0;
        box-shadow: 0px 2px 10px 0px #00000021;
        .permission-block-content {
          width: 100%;
          height: 100%;
          box-shadow: none;
          .role-block-list-wrapper {
            margin-top: 0;
            .role-block-list {
              padding-right: 32px;
            }
          }
          .scroll {
            padding-left: 0;
          }
          &.role-accordion-list {
            .scroll-wrapper {
              .scroll {
                padding: 0 16px;
              }
            }
            .role-block-list-wrapper {
              margin-top: 0;
              .role-block-list {
                padding-right: 0;
              }
            }
          }
        }
      }

    }
  }
}
.panel{
  background-color: red;
}
.role-block {
  display: flex;
  flex-direction: column;
  .role-block-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0 24px;
    align-items: center;
    height: 66px;
    .role-block-header-title {
      color: #001636DE;
      font-size: 18px;
      font-weight: 500;
      line-height: 120%;
    }
    .role-block-header-button {
      margin-left: auto;
      .btn {
        display: flex;
        align-items: center;
        padding: 3px 16px 3px 12px;
        .button-icons {
          margin: 0;
          margin-right: 8px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .role-block-search {
    padding: 0 24px;
    .search-icon {
      color: #758192;
    }
    .input-group{
      .form-control {
        padding: 12px 16px;
      }
    }
  }
  .role-block-list-wrapper {
    flex: 1;
    margin-top: 12px;
    position: relative;
    .role-block-list {
      max-height: 100%;
      height: 100%;
      position: absolute;
      width: 100%;
      .scroll-wrapper {
        height: 100%;

      }
      .rbl-item {
        padding: 8px 24px;
        border-bottom: 1px solid #E2E8F1;
        //&:last-child {
        //  border-bottom: 0;
        //}
        .rbl-more-wrapper {}
        &.selected {
          background: #EAF5FF;
        }
        &:hover {
          cursor: pointer;
          background: #EAF5FF;
        }
        &:first-child{
          border-top: 1px solid #E2E8F1;
        }
      }
      .role-item,
      .user-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 59px;
        .user-item-select {
          width: 48px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          .user-item-checkbox {

          }
        }
        .user-item-avatar {
          width: 38px;
          height: 38px;
          border-radius: 38px;
          margin-right: 8px;
          overflow: hidden;
          flex-shrink: 0;
          img {
            margin: 0;
            padding: 0;
            max-height: 100%;
          }
        }
        .user-item-remove {
          display: none;
        }
        &:hover{
          .user-item-remove {
            margin-left: auto;
            display: flex;
            color: #758192;
            flex-shrink: 0;
            .bi:hover {
              color: red;
            }
            .locked-events {
              pointer-events: none;
            }
          }
          .rbl-item-more{
            display: flex;
          } 
        }
        .rbl-item-name {
          color: #213450;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
        }
        .user-item-role {
          color: #758192;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
        .rbl-item-more {
          display: none;
          gap: 12px;
          margin-left: auto;
          margin-right: 4px;
          text-align: center;
          color: #758192;
          .delete-outline:hover{
            color: red;
          }
          .rename-outline:hover{
            color: #1665A7;
          }
        }
      }
      .role-item {
        padding-right: 4px;
      }
    }

    .rbl-accordion {
      background: #fff;
      padding: 7px 0;
      border-bottom: 1px solid #B3B9C3;
      &:last-child {
        border-bottom: none;
      }
      .rbl-accordion-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding-right: 16px;
        .rbl-accordion-name {
          color: rgba(0, 22, 54, 0.87);
          font-size: 16px;
          font-weight: 500;
          line-height: 120%;
          display: flex;
          align-items: center;
          flex-direction: row;
          flex: 1;
          .bordered-checkbox {
            .rbl-group-checkbox {
              margin-top: 0;
            }
          }
          .rbl-group-name {
            padding: 12px 0;
            flex: 1;
            &.disabled {
              opacity: 0.5;
            }
          }
        }
        .rbl-accordion-arrow {
          margin-left: auto;
        }
      }
      .rbl-accordion-permissions {
        display: none;
        .rbl-accordion-item {
          border-top: 1px solid #B3B9C3;
          // remove
          padding-left: 32px;
          .permission-field {

          }
        }
      }
      .form-check-input {
        margin-left: 14px;
        margin-right: 14px;
      }
      .form-check-label {
        margin-left: 16px;
        color: #213450;
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;
      }
      &.open {
        .rbl-accordion-header {
          .rbl-accordion-arrow {
            transform: rotate(180deg);
          }
        }
        .rbl-accordion-permissions {
          display: block;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .role-block-list-footer {
    padding: 16px 16px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      width: 172px;
      height: 40px;
    }
    .rbl-save {
      margin-right: 8px;
    }
    .rbl-cancel {
      margin-left: 8px;
    }
  }
}

.role-create-popup {
  z-index: 2051;
  .modal-dialog {
    z-index: 2051;
    .modal-content {
      border: none;
      border-radius: 8px;
      .modal-header {
        border-bottom: 1px solid #D4D9E3;
        padding: 16px;
        .header-text {
          color: rgba(0, 22, 54, 0.87);
          font-size: 24px;
          font-weight: 682;
          line-height: 134%;
        }
      }
      .modal-body {
        .display-name-field,
        .permission-field {
          .form-control {
            height: 46px;
            background-color: #F4F7FB;
            border: none;
          }
          .form-label{
            color: #213450;
            font-weight: 500;
          }
          .input-block {
            &:focus,
            &:focus-within {
              .form-input-label {
                top: -10px;
              }
            }
            .form-input-label {
              top: -10px;
            }
            .form-control[value=""]~.form-input-label {
              top: 11px;
            }
            .form-control:-webkit-autofill~.form-input-label,
            .form-control:focus~.form-input-label {
              top: -10px;
            }
          }
        }
        .display-name-field {
          margin-top: 24px;
        }
      }
      .modal-footer {
        .btn {
          width: 172px;
          height: 40px;
        }
        gap: 5px;
        justify-content: center;
      }
    }
  }
  .modal-xl {
    --bs-modal-width: 706px;
  }

}

.rbl-more-tooltip {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
}

.rbl-more-wrapper {
  padding: 6px 0;
  min-width: 212px;
  .rbl-more-button {
    text-align: left;
    padding: 10px;
    border-radius: 0;
    color: #213450;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #B3B9C3;
    align-items: center;
    &:last-child {
      border-bottom: none;
    }
    &.remove {
      color: #B71919;
    }
  }
}

.select-user-list-portal {
  .select-user-list-wrapper {
    width: 400px;
    height: 620px;
    display: flex;
    padding: 0;
    .role-block {
      width: 100%;
      .role-block-list-wrapper .role-block-list {
        .rbl-item {
          padding: 8px 0;
        }
      }
    }
  }
}

.role-list-wrapper {
  .not-select-user {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .selected-user {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .su-info {
      padding: 22px 24px;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex: 1;
      .su-common-data {
        width: 62%;
        flex: 1;
        padding-right: 16px;
        .su-header {
          color: rgba(0, 22, 54, 0.87);
          font-size: 18px;
          font-weight: 682;
          line-height: 140%; /* 25.2px */
        }
        .form-control-wrapper {
          margin-top: 16px;
          .locale-selector,
          .form-control {
            border: none;
            background: #F4F7FB;
            padding: 8px 16px;
            font-size: 16px;
            font-weight: 400;
            line-height: 140%;
          }
          .locale-selector {
            background: none;
          }
          .input-group {
            border: none;
            background: #F4F7FB;
            justify-content: space-between;
            .input-group-text {
              background-color: transparent;
            }
          }
        }
      }
      .su-avatar {
        width: 32%;
        min-width: 240px;
        .su-header {
          color: rgba(0, 22, 54, 0.87);
          font-size: 16px;
          font-weight: 500;
          line-height: 120%; /* 19.2px */
        }
        .su-avatar-block {
          width: 100%;
          margin: auto;
          margin-top: 16px;
          overflow: hidden;
          display: flex;
          align-items: center;
          .avatar-img {
            width: 100%;
          }
          &:before {
            content: "";
            padding-top: 100%;
            float: left;
          }
        }
        .su-avatar-load-block {
          margin-top: 16px;
          .avatar-load {
            justify-content: center;
            align-items: center;
            .form-file-label {
              .form-file-button {
                border-radius: 4px;
                background: #FFF;
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.13);
                color: #1665A7;
                padding: 8px 16px;
                line-height: 1;
                .button-icons {
                  margin: 0 8px 0 0;
                }
              }
            }
          }
        }
      }
    }
    .su-buttons {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn {
        width: 172px;
        height: 40px;
      }
      .su-save {margin-right: 8px}
      .su-cancel {margin-left: 8px}
    }
  }
}

.locale-selector {
  height: 38px;
}

.locale-selector-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  .locale-item-image {
    margin-right: 12px;
  }
  .locale-item-name {
    color: #213450;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
  }
}

@media (max-width: 1799px) {
  .select-user-list-portal {
    .select-user-list-wrapper {
      width: 400px;
      height: 480px;
      .rbl-item {
        padding: 3px 0;
        .user-item-info {
          .rbl-item-name {
            font-size: 13px;
            font-weight: 400;
          }
          .user-item-role {
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .role-page {
    .permission-block-content {
      &.role-accordion-list {
        .role-block-list-wrapper {
          margin-top: 0;
          .role-block-list {
            .scroll-wrapper {
              .scroll {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }

  .role-block {
    .role-block-list-wrapper .rbl-accordion {
      .form-check-label {
        font-size: 14px;
        line-height: 143%; /* 20.02px */
      }
      .rbl-accordion-header .rbl-accordion-name .rbl-group-name {
        font-size: 14px;
        line-height: 143%; /* 20.02px */
        padding: 6px 0;
      }
      .bordered-checkbox, .check-box-with-label {
        padding: 6px 0;
      }
    }
    .role-block-list-wrapper .role-block-list .role-item,
    .role-block-list-wrapper .role-block-list .user-item {
      min-height: 42px;
      .rbl-item-name {
        font-size: 13px;
        line-height: 120%;
      }
      .user-item-role {
        font-size: 12px;
        line-height: 12px;
      }
      .user-item-avatar {
        width: 24px;
        height: 24px;
      }
    }
    .role-block-header .role-block-header-button .btn {
      font-size: 14px;
    }
  }
}

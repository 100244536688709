.radio-control-list {
  .form-label {
    color: var(--text-primary, #213450);
    font-size: 16px;
    font-weight: 500;
  }
  .radio-btn-group {
    .form-check {
      width: 100%;
      padding: 12px 0;
      .form-check-input {
        display: none;
        margin-left: 0;
      }
      .form-check-label {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        .form-check-input-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 20px;
          height: 20px;
          margin-right: 10px;
          border-radius: 20px;
          border: 2px solid #758192;
          box-sizing: border-box;
        }
      }
      .form-check-input:checked + .form-check-label {
        .form-check-input-icon {
          border: 2px solid #1665A7;
          &:before {
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 10px;
            background: #1665A7;
          }
        }
      }


    }
  }
}

:root {
  --size: 30px;
}

.gov-number {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #111d29;
  border-radius: calc(var(--size) / 6) ;
  height: var(--size);
  width: calc(var(--size) * 4.64);
  box-shadow: 1px 1px 1px 0px rgba(34, 60, 80, 0.5);

  .gov-number-container {
    display: flex;
    font-family: "AUTONUMBER";
    justify-content: space-between;
    color: black;
    background-color: black;
    width: 100%;
    height: 100%;
    border-radius: calc(var(--size) / 8) ;

    .gov-number-number {
      display: flex;
      align-items: center;
      width: 75%;
      background-color: white;
      padding-left: calc(var(--size) / 3.54);
      padding-right: calc(var(--size) / 7.7);
      padding-top: calc(var(--size) / 12);
      border-radius: calc(var(--size) / 8) 0px 0px calc(var(--size) / 8);
      border-right: 1px solid black;

      .gov-number-number-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: calc(var(--size) / 1.3);
        width: 100%;
        height: 100%;

        .gov-number-number-int, .gov-number-number-char {
          font-family: "AUTONUMBER";
        }

        .gov-number-number-int {
          line-height: normal;
          letter-spacing: calc(var(--size) / 9.2);
        }
        
        .gov-number-number-char{
          line-height: normal;
          letter-spacing: calc(var(--size) / 11.5);
        }
      }
    }

    .gov-number-region {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      width: 25%;
      height: 100%;
      padding-left:  calc(var(--size) / 25);
      background-color: white;
      padding-top: calc(var(--size) / 20);
      border-radius:  0px calc(var(--size) / 8) calc(var(--size) / 8) 0px;

      .gov-number-region-number {
        font-family: "AUTONUMBER";
        font-size: calc(var(--size) / 1.6);
        line-height: 85%;
        letter-spacing: calc(var(--size) * 0.06);
        .gov-number-region-container{
          display: none;
        }
      }

      .gov-number-region-container{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-evenly;

        .gov-number-region-name {
          font-size: calc(var(--size) / 4);
          font-weight: 700;
          line-height: normal;
          align-self: center;
        }

        .gov-number-region-flag {
          display: flex;
          flex-direction: column;
          width: calc(var(--size) / 4);
          height: calc(var(--size) / 6);
          box-shadow: 0px 0px 0px 0.3px;
          justify-content: space-evenly;
          align-content: space-between;
          background-color: black;

          .strip {
            display: block;
            width: 100%;
            height: 33%;
            background-color: blue;
          }
          .strip:first-child {
            display: block;
            width: 100%;
            background-color: white;
          }
          .strip:last-child {
            background-color: red;
          }
        }
      }
    }

    &.long {
      .gov-number-number{
        width: 70%;
      }
      .gov-number-region{
        width: 30%;
      }
    }
  }

  &.moto{
    height: calc(var(--size) * 1.7);
    width: calc(var(--size) * 2.4);

    .gov-number-container{
      flex-direction: column;
      justify-content: space-between;

      .gov-number-number, .gov-number-region{
        width: 100%;
        height: 50%;
        position: relative;

        .gov-number-region-contaner{
          display: none;
          background-color: black;
        }
      }

      .gov-number-number{
        border-radius:  calc(var(--size) / 6) calc(var(--size) / 6) 0 0;
        padding: 0;
        border-right: 0;
        .gov-number-number-container{
          justify-content: center; 
        }
      }

      .gov-number-region{
        flex-direction: row;
        justify-content: space-between;
        padding: 0;
        border-radius: 0px 0px calc(var(--size) / 8) calc(var(--size) / 8);                                                          
        
        .gov-number-region-container{
          display: none;
        }

        .gov-number-region-number{
          background-color: white;
          width: 45%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
          border-left: 1px solid black;
          border-top: 1px solid black;
          font-size: calc(var(--size) / 1.7);
          font-family: "AUTONUMBER";
          align-items: center;
          border-radius:  calc(var(--size) / 6) 0 calc(var(--size) / 6) 0;

          .gov-number-region-container{
            display: flex;

            .gov-number-region-name{
              font-size: calc(var(--size) / 4);
              letter-spacing: normal
            }
          }
          
        }
        .gov-number-number-char{
          width: 54%;
          display: flex;
          justify-content: center;
          font-family: "AUTONUMBER";
          font-size: calc(var(--size) / 1.1);
          align-items: center;
          height: 100%;
          background-color: white;
          border-radius:  0 0 0 calc(var(--size) / 6);
        }
      }
    }

  }

  &.ru_yellow{
    .gov-number-number, .gov-number-region {
      background-color: #FFD900;
      .gov-number-number-container {
        justify-content: start;
      }
    }
  }

  &.trailers{
    .gov-number-number,.gov-number-region{
      background-color: white;
    }
  }

  &.ru_black{
    border: 1px solid white;
    .gov-number-container{
      background-color:white;
      .gov-number-number,.gov-number-region{
        background-color: #191310;
        color: white;
      }
      .gov-number-number{
        border-right: 1px solid white;
      }
    }
  }

  &.ru_red{
    border: 1px solid white;
    .gov-number-container{
      background-color:white;
      .gov-number-number{
        border-right: 1px solid white;
      }
      .gov-number-number,.gov-number-region{
        background-color: #ff0000;
        color: white;
        .gov-number-number-int {
          letter-spacing: calc(var(--size) / 18.2);
        }
        
        .gov-number-number-char{
          letter-spacing: calc(var(--size) / 20.5);
        }
      }
    }
  }

  &.ru_blue{
    border: 1px solid white;
    .gov-number-container{
      background-color:white;
      .gov-number-number{
        border-right: 1px solid white;
      }
      .gov-number-number,.gov-number-region{
        background-color: #064db9;
        color: white;
      }
      .gov-number-number{
        padding: 0;
        .gov-number-number-container{
          justify-content: space-evenly;
        }
      }
    }
  }

  &.temp{
    color: black;
    .gov-number-container{
      background-color:black;
      .gov-number-number,.gov-number-region{
        background-color: white;
      }
      .gov-number-region{
        background-color: #FFD900;
      }
      .gov-number-number{
        padding: 0;
        .gov-number-number-container{
          justify-content: space-evenly;
        }
      }
    }
  }
}

.check-box-field-formatter{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-formatter{
  .tooltip-content{
    .click-wrapper{
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      max-height: 3em;
      white-space: wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;   
    }
  }
}

.session-number-formatter{
  color: #1665A7;
  &:hover{
    cursor: pointer;
    text-decoration: underline;
  }
}

.client-type-formatter{
  display: flex;
  justify-content: start;
  align-items: center;
  .client-type-formatter-box{
    display: flex;
    width: 134px;
    height: 24px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #91BADC;
    font-size: 14px;
    font-weight: 682;
    color: white;
    line-height: 20px;
  }
}

.service-status-formatter, .client-status-formatter{
  display: flex;
  justify-content: center;
  align-items: center;

  .service-status-formatter-box, .client-status-formatter-box{
    display: flex;
    width: 190px;
    height: 24px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    background-color: #6AD387;
    color: white;
    font-size: 14px;
    font-weight: 682;
    line-height: 20px;

    &.error{
      background-color: #E96363;
    }
    &.warning{
      background-color: #FFBC59;
    }
  }
}

.session-status-formatter{
  display: flex;
  justify-content: center;
  align-items: center;
  .session-status-formatter-box{
    display: flex;
    width: 108px;
    height: 24px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    background-color: #A6AEB9;
    color: white;
    font-size: 14px;
    font-weight: 682;
    line-height: 20px;

    &.sucssesful{
      background-color: #6AD387;
    }
  }
}

.host-name-formatter{
  color: #1665A7;
  &:hover{
    cursor: pointer;
    text-decoration: underline;
  }
}

.status-notification-formatter{
  display: flex;
  justify-content: start;
  padding: 0px 6px;
  align-items: center;
  width: 113px;
  height: 26px;
  font-weight: 682;
  font-size: 14px;
  gap: 6px;
  color: #08A835;
  background-color: #E7F4EB;
  border-radius: 24px;
  &.error{
    color: #DE0F0F;
    width: 91px;
    background-color: #FFEFEF;
  }
  &.warning{
    color: #FFA41F;
    width: 151px;
    background-color: #FFF4E5;
  }
  .status-notification-formatter-text{
    line-height: 22px;
  }
}

.control-box-formatter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    .control-box-button {
      display: flex;
      position: relative;
      height: 36px;
      width: 36px;
      border: 4px;
      border-radius: 4px;
      box-shadow: 0px 0px 10px 0px #00000040;
      justify-content: center;
      align-items: center;
      color:  #213450;
      &:hover {
        color: white;
        background: #1665A7;;
        cursor: pointer;
      }
      .expanded-click-zone{
        position: absolute;
        width: 150%;
        height: 150%;
      }
    }
}

.visual-park {
  display: flex;
  justify-content: center;
  align-items: center;
}

.host {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #213450;


  .host-assembly {
    display: flex;
    justify-content: end;
    .host-assembly-icon {
      color: #d4d9e3;
      position: relative;

      &:hover{
        color: #1665a7;
      }

      .field-click-ext{
        position: absolute;
        top: -50%;
        left: -50%;
        width: 200%;
        height: 200%;
        cursor: pointer;
      }
    }
  }

  .host-name {
    display: flex;
    align-items: baseline;
    line-height: 23px;
    gap: 8px;

    .host-status-circle {
      height: 12px;
      flex-shrink: 0;
      width: 12px;
      background-color:#DE0F0F;
      border-radius: 100%;
      &.online{
        background-color: #08a835;
      }
    }
  }

  .host-path-type{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    margin-left: 20px;
    color: #758192;

    .host-type {
      display: flex;
      font-size: 14px;
      line-height: 19.6px;
      margin-top: 3px;
    }
  }
}

.host-status-formatter{
  display: flex;
  width: 20px;
  height: 20px;
  line-height: 14px;
  justify-content: center;
  align-items: center;
  background-color: #6AD387;
  border-radius: 100%;
  color: #FFFFFF;
  font-size: 10px;
  font-weight: 700;
  &.isError{
    background-color: #E96363;
  }
  &.isOff{
    background-color: #A6AEB9;
  }
}

.equipments-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .equipments-group-item {
    position: relative;
    margin-right: 12px;
    .equipments-group-item-status {
      position: absolute;
      bottom: 3px;
      right: 4px;
    }
  }
  .equipments-group-item:last-child {
    margin-right: 0px;
  }
}

.log {
  line-height: 22.4px;
  color: #213450;
  .log-time {
    white-space: nowrap;
    margin-right: 8px;
  }
}

.column-relative{
  position: relative;
}
.login-page-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  .login-page {
    position: relative;
    width: 100%;
    height: 100%;
    background: no-repeat url("../../img/parking-meter.png");
    background-size: 100%;
    min-height: 837px;
    min-width: 800px;
    overflow: hidden;
    .data-wrapper {
      position: relative;
      display: table;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .data-block {
        position: relative;
        display: table-cell;
        vertical-align: middle;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .form-block {
          width: 100%;
          max-width: 900px;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          padding: 64px;
          background: #FFFFFF;
          border-radius: 4px;
          .header {
            h2 {
              margin-top: 24px;
            }
          }
          .login-form {
            width: 500px;
            margin-top: 24px;
            .forgot-password-success {
              color: #09912F;
              font-size: 16px;
              line-height: 140%;
              margin-bottom: 24px;
            }
            .login-button {
              width: 100%;
              margin-top: 48px;
            }
            .forgot-password {
              cursor: pointer;
              color: $primary;
              margin: 32px 16px 0;
            }
            .login-button-wrapper {
              .login-button {
                width: 48%;
                margin-left: 1%;
              }
              .cancel-button {
                width: 48%;
                margin-top: 48px;
                margin-right: 1%;
              }
            }
            .password-field {
              margin-top: 24px;
            }
          }
        }
      }
    }
    .copyright{
      border: 1px solid #E2E8F1;
      backdrop-filter: blur(10px);
      height: 107px;
      width: 318px;
      padding: 20px 0px;
      margin: 0 auto;
      margin-top: 24px;
      font-size: 16px;
      font-weight: 500;
      color: white;
      text-align: center;
    }
  }
}

@media (max-width: 1799px) {
  .login-page-wrapper {
    .login-page {
      min-height: 600px;
      min-width: 700px;
      .data-wrapper {
        .data-block {
          .form-block {
            max-width: 530px;
            padding: 32px 64px;
            .header {
              h1 {
                font-size: 28px;
                font-weight: 500;
                line-height: 120%;
              }
              h2 {
                font-size: 18px;
                font-weight: 682;
                line-height: 140%;
                margin-top: 16px;
              }
            }
            .login-form {
              width: 400px;
              margin-top: 16px;
            }


          }
        }
      }
      .copyright {
        margin-top: 38px;
      }
    }
  }
}

.mobile-layout {
  .login-page-wrapper {
    .login-page {
      min-height: 320px;
      min-width: 320px;
      overflow: auto;
      .data-wrapper {
        display: block;
        width: 100%;
        height: 100%;
        .data-block {
          display: flex;
          width: 100%;
          height: 100%;
          min-height: 480px;
          background: #ffffff;
          flex-direction: column;
          .form-block {
            padding: 32px 16px;
            border-radius: 0;
            .header {
              h1 {
                text-align: center;
                font-size: 18px;
                font-weight: 682;
                line-height: 140%;
              }
              h2 {
                margin: 0;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                line-height: 120%; /* 19.2px */
              }
            }
            .login-form {
              width: 100%;
              .password-field {
                margin-top: 16px;
              }
              .login-button {
                margin-top: 24px;
              }
              .cancel-button {
                margin-top: 24px;
              }
              .forgot-password {
                margin: 24px 0 0;
                padding: 10px 12px;
              }
            }
          }
          .copyright {
            display: none;
          }
          .support-agent-block {
            display: flex;
            flex-direction: column;
            .support-agent-icon-wrapper {
              flex: 1;
              position: relative;
              cursor: pointer;
              .support-agent-icon {
                position: absolute;
                right: 16px;
                bottom: 32px;
                width: 52px;
                height: 52px;
              }
            }
            .support-agent-popup {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              .sap-backdrop {
                width: 100%;
                height: 100%;
                opacity: 0.35;
                background: #213450;
                z-index: 1;
              }
              .sap-data {
                position: absolute;
                bottom: 0;
                width: 100%;
                z-index: 2;
                background: #fff;
                border-radius: 4px 4px 0 0;
                padding: 8px 16px 16px;
                .sap-header {
                  display: flex;
                  flex-direction: row;
                  margin: 16px 0;
                  align-items: center;
                  .sap-title {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 120%; /* 19.2px */
                  }
                  .sap-close {
                    margin-left: auto;
                  }
                }
                .sap-body {
                  .sap-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin: 12px 0;
                    text-decoration: none;
                    .sap-icon {
                      width: 24px;
                      height: 24px;
                      margin-right: 8px;
                    }
                    .sap-item-text {
                      color: #213450;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 140%; /* 22.4px */
                    }
                  }
                }
              }

            }
            flex: 1;
          }
        }
      }
    }
  }
}

.zones-page {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 28px 32px;
  font-family: '"GOSTUI2", "Helvetica Neue", Helvetica, Arial, sans-serif';
  justify-content: space-between;

  .zones-page-wrapper {
    gap: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    
    .grid-zones {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        align-content: start;
        background-color: white;
        box-shadow: 0px 2px 10px 0px #00000021;
        border-radius: 4px;
        flex-wrap: wrap;
        gap: 16px;
        padding: 24px;
        height: 100%;

        .zone {
          display: flex;
          height: 272px;
          width: 49.5%;
          flex-direction: column;
          justify-content:space-between;
          border: 1px solid #E2E8F1;
          background: white;
          padding: 24px;
          border-radius: 4px;
          
          .zone-info {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .separate{
              width: 1px;
              height: 100%;
              background-color: #E2E8F1;
            }

            .zone-info-statuses {
              display: flex;
              flex-direction: column;
              gap: 16px;
              width: 65.07%;
  
              .status {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 32px;
  
                .status-title {
                  display: flex;
                  align-items: center;
                  gap: 15px;
                  font-size: 16px;
                }
  
                .status-count {
                  font-size: 20px;
                  font-weight: 400;
                  line-height: 28px;
                }
              }
            }
  
            .zone_stat {
              width: 30.5%;
              border-radius: 4px;
              display: flex;
              height: 100%;
              background-color: #eaf5ff;
              justify-content: center;
              gap:10px;
              align-items: center;
              flex-direction: column;

              .zone_stat_title {
                font-size: 18px;
                font-weight: 400;
                line-height: 25.2px;
              }

              .zone-stat-count {
                font-size: 64px;
                font-weight: 400;
                line-height: 89.6px;
              }
            }
          }
  
          .zone-title {
            font-size: 18px;
            font-weight: 500;
            display: flex;
            justify-content: space-between;
            .zone-title-control-button{
              display: flex;
              gap: 16px;
              color: #1665A7;
              .zone-title-control-button-box{
                display: flex;
                width: 56px;
                height: 32px;
                gap: 8px;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                box-shadow: 0px 2px 10px 0px #1665A766;
              }
              .zone-title-control-button-box:hover{
                color: white;
                cursor: pointer;
                background-color: #1665A7;
              }
            }
          }
          
          &.isMiniatures{
            width: calc((100%/4) - 16px);
            height: 210px;
            padding: 16px;

            .zone_stat{
              width: 25.5%;
            }
            .zone_stat{
              gap: 17px;
              padding-top: 8px;
              justify-content: start;
              .zone_stat_title{
                font-size: 14px;
                line-height: 19.6px;
              }
              .zone-stat-count{
                font-size: 32px;
                line-height: 44.8px;
              }
            }
            .zone-info-statuses{
              gap: 0;   
              width: 68%; 
              .status .status-title{
                  font-size: 14px;
                  line-height: 18.2px;
              }
            }
          }
        }
    }
  }
}

.zone-card-edit-popup{
  .modal-content{
    height: 693px;
    z-index: 9999999;
    border: 0;
    .hff-title{
      .header-buttons{
        justify-content: space-between;
      }
      .hff-close-wrapper{
        cursor: pointer;
        color: #758192;
      }
    }
    .hff-content{
      display: flex;
      padding: 32px 24px;
      flex-direction: column;
      gap: 16px;
      font-size: 16px;
      font-weight: 500;
      .form-label{
        line-height: 19px;
      }
      .form-control{
        padding: 15px 16px;
        background-color: #F4F7FB;
        font-size: 16px;
        line-height: 22px;
        border: none;
        color: #213450;
        position: relative;
      }
      .icon-edit{
        position: absolute;
        top: 14px;
        right: 14px;
      }
    }
    .hff-footer{
      justify-content: center;
      align-items: center;
      height: 80px;
      gap: 16px;
    }
  }
  .modal-xl {
    --bs-modal-width: 688px;
  }
}

.zone-card-add-option-popup{
  .modal-content{
    height: 511px;
    z-index: 9999999;
    border: 0;
    .hff-title{
      .header-buttons{
        justify-content: space-between;
        .hff-title-text{
          width: 400px;
        }
      }
      .hff-close-wrapper{
        cursor: pointer;
        color: #758192;
      }
    }
    .hff-content{
      display: flex;
      padding: 32px 24px;
      flex-direction: column;
      gap: 16px;
      font-size: 16px;
      font-weight: 500;
      .form-control{
        padding: 10px 9px;
        background-color: #F4F7FB;
        font-size: 16px;
        line-height: 22px;
        border: none;
        color: #213450;
        position: relative;
      }
      .input-group-text{
        background-color: #F4F7FB;
      }
    }
    .hff-footer{
      justify-content: center;
      align-items: center;
      gap: 16px;
      height: 80px;
    }
  }
  .modal-m {
    --bs-modal-width: 705px;
  }
}

.zone-card-list{
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 892px; 
  width: 25.4%;
  min-width: 456px;
  background-color: white;
  flex-shrink: 0;
  border-radius: 4px;
  box-shadow: 0px 2px 10px 0px #00000021;
  .zone-card-zones-header{
    display: flex;
    padding: 17px 24px;
    justify-content: space-between;
    align-items: center;
    .zone-card-zones-title{
      font-size: 18px;
      font-weight: 500;
      color: #001636DE;
    }
    .zone-card-zones-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 135px;
      height: 32px;
      font-size: 16px;
      border-radius: 4px;
      border: 2px solid #1665A7;
      color: #1665A7;
      gap: 8px;
      cursor: pointer;
      &:hover{
        background-color: #EAF5FF;
      }
      &.without-border{
        border: none;
      }
    }
  }
  .zone-card-zones-container{
    display: flex;
    flex-direction: column;
    .zone-card-zones-item{
      display: flex;
      height: 52px;
      align-items: center;
      color: #001636DE;
      font-size: 14px;
      border-top: 1px solid #E2E8F1;
      padding: 0px 24px;
      &:hover{
        background-color: #EAF5FF;
        cursor: pointer;
      }
      &.selected{
        background-color: #EAF5FF;
      }
      &:last-child{
        border-bottom: 1px solid #E2E8F1;
      }
    }
    &.empty{
      height: 100%;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      color: #00163661;
    }
  }
}
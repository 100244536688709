html {
  --scrollbarBG: #fff;
  //--scrollbarBG: red;
  --thumbBG: #91BADC;
}
body {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #EDF0F6;
  color: #213450;
}
*{
  font-family: 'GOSTUI2', "Helvetica Neue", Helvetica, Arial, sans-serif;
}

div {
  box-sizing: border-box;
}

h1, .f1 {
  font-size: 36px;
  font-weight: 500;
}
h2, .f2 {
  font-size: 24px;
  font-weight: 700;
}
h3, .f3 {
  font-size: 18px;
  font-weight: 700;
}
h4, .f4 {
  font-size: 16px;
  font-weight: 500;
}
.f5 {
  font-size: 16px;
  font-weight: 400;
}
.f6 {
  font-size: 14px;
  font-weight: 500;
}
.f7 {
  font-size: 14px;
  font-weight: 400;
}
.f8 {
  font-size: 12px;
  font-weight: 400;
}
.f9 {
  font-size: 8px;
  font-weight: 500;
}

#root {
  width: 100%;
  height: 100%;
}

.btn {
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  padding: 6px;
  &.big {
    padding: 16px;
  }
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1665A7;
  --bs-btn-border-color: #1665A7;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #085290;
  --bs-btn-hover-border-color: #085290;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #085290;
  --bs-btn-active-border-color: #085290;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #A6AEB9;
  --bs-btn-disabled-border-color: #A6AEB9;
}

.btn-secondary {
  --bs-btn-color: #1665A7;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #1665A7;
  --bs-btn-hover-color: #085290;
  --bs-btn-hover-bg: #EAF5FF;
  --bs-btn-hover-border-color: #085290;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #085290;
  --bs-btn-active-bg: #EAF5FF;
  --bs-btn-active-border-color: #085290;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #A6AEB9;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #A6AEB9;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: #1665A7;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #085290;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #A6AEB9;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: none;
  &.active,
  &:hover {
    background-color: #EAF5FF;
  }
}

.wrapper-not-found {
  font-size:24px;
  width: fit-content;
  margin: 0 auto;
}

.min-vp-100 {
  min-height: 100%;
}

.ellipsis{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.error-state {
  color: #B71919!important;
}
.warning-state {
  color: #E38905!important;
}
.good-state {
  color: #08A835!important;
}
.color-gray {
  color: #758192;
}
.color-light-gray {
  color: #B3B9C3;
}
.color-green {
  color: #08A835;
  //color: #09912F;
}
.color-orange {
  color: #FFA41F;
}
.status-formatter {
  .status-text {
    line-height: 140%;
  }
  .last-ping-at {
    display: flex;
    flex-direction: row;
    color: #213450;
    line-height: 140%;
    .pmh-icon-wrapper {
      margin-left: auto;
    }
    .pmh-icon {
      margin-left: 7px;
      width: 19px;
      height: 19px;
      color: #A6AEB9;
    }
  }
}
.status-with-problems-toggle {
  white-space: normal;
  .status-with-problems {
    cursor: pointer;
  }
}

.alert {
  padding: 8px;
  background: transparent;
  //border: none;
  border-radius: 4px;
  border-color: #B71919;
  .alert-danger {
    color: #B71919;
  }
}


.apexcharts-xaxis-title {
  white-space: pre-wrap;
}

.close {
  width: 14px;
  height: 14px;
  color: #758192;
  cursor: pointer;
}

.scroll-wrapper {
  flex: 1;
  display: flex;
  position: relative;
  overflow: hidden;
  .scroll {
    // padding-right: 32px;
    position: absolute;
    width: 100%;
    flex: 1;
    z-index: 1;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transform: translateZ(0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-size-adjust: none;
  }
}

.iScrollVerticalScrollbar {
  z-index: 99!important;
  width: 4px!important;
  right: 7px!important;
  overflow: visible!important;
  .iScrollIndicator {
    width: 6px!important;
    border-radius: 16px!important;
    background: #91BADC!important;
    border: none!important;
    cursor: pointer;
    transition: width 1s;
    transition-duration: 0.5s!important;
    right: 0!important;
    &:active,
    &:hover {
      width: 10px!important;
      transition: width, right;

    }
  }
}

.iScrollHorizontalScrollbar {
  .iScrollIndicator {
    height: 6px!important;
    border-radius: 16px!important;
    background: #91BADC!important;
    border: none!important;
  }
}


.mobile-layout {
  .btn {
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    padding: 6px;
    &.big {
      padding: 11px;
    }
  }
}

@media (max-width: 1799px) {
  .apexcharts-title-text {
    font-size: 16px;
  }
  .scroll-wrapper {
    // .scroll {
    //   padding-right: 24px;
    // }
  }
}

.locked-events {
  opacity: 0.65;
}

.search-field {
  width: 347px;
}

.default-field {
  width: 163px;
}

.form-check-input {
  width: 18px;
  height: 18px;
  margin-left: 0;
  border: none;
  background-color: transparent;
  border-radius: 0;
  cursor: pointer;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNC4zMzQgMC43NUg1LjY2NUMyLjY0NCAwLjc1IDAuNzUgMi44ODkgMC43NSA1LjkxNlYxNC4wODRDMC43NSAxNy4xMTEgMi42MzUgMTkuMjUgNS42NjUgMTkuMjVIMTQuMzMzQzE3LjM2NCAxOS4yNSAxOS4yNSAxNy4xMTEgMTkuMjUgMTQuMDg0VjUuOTE2QzE5LjI1IDIuODg5IDE3LjM2NCAwLjc1IDE0LjMzNCAwLjc1WiIgc3Ryb2tlPSIjNzU4MTkyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
  &.some-child {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01IDBDMi4yMzg1OCAwIDAgMi4yMzg1OCAwIDVWMTVDMCAxNy43NjE0IDIuMjM4NTggMjAgNSAyMEgxNUMxNy43NjE0IDIwIDIwIDE3Ljc2MTQgMjAgMTVWNUMyMCAyLjIzODU4IDE3Ljc2MTQgMCAxNSAwSDVaTTYgOUM1LjQ0NzcyIDkgNSA5LjQ0NzcxIDUgMTBDNSAxMC41NTIzIDUuNDQ3NzIgMTEgNiAxMUgxNEMxNC41NTIzIDExIDE1IDEwLjU1MjMgMTUgMTBDMTUgOS40NDc3MSAxNC41NTIzIDkgMTQgOUg2WiIgZmlsbD0iIzE2NjVBNyIvPgo8L3N2Zz4K");
  }
  &[type=checkbox]:checked {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01LjY3IDBIMTQuMzRDMTcuNzMgMCAyMCAyLjM4IDIwIDUuOTJWMTQuMDkxQzIwIDE3LjYyIDE3LjczIDIwIDE0LjM0IDIwSDUuNjdDMi4yOCAyMCAwIDE3LjYyIDAgMTQuMDkxVjUuOTJDMCAyLjM4IDIuMjggMCA1LjY3IDBaTTkuNDMgMTIuOTlMMTQuMTggOC4yNEMxNC41MiA3LjkgMTQuNTIgNy4zNSAxNC4xOCA3QzEzLjg0IDYuNjYgMTMuMjggNi42NiAxMi45NCA3TDguODEgMTEuMTNMNy4wNiA5LjM4QzYuNzIgOS4wNCA2LjE2IDkuMDQgNS44MiA5LjM4QzUuNDggOS43MiA1LjQ4IDEwLjI3IDUuODIgMTAuNjJMOC4yIDEyLjk5QzguMzcgMTMuMTYgOC41OSAxMy4yNCA4LjgxIDEzLjI0QzkuMDQgMTMuMjQgOS4yNiAxMy4xNiA5LjQzIDEyLjk5WiIgZmlsbD0iIzE2NjVBNyIvPgo8L3N2Zz4K");
    background-color: white;
  }
  &:focus {
    box-shadow: none;
  }
}

.row-full-click{
  position: relative;
  .dropdown{
    position: static;
    .click-wrapper{
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      &:hover{
        color: #1665A7;
      }
    }
  }
}


.custom-scroll-theme {
  //width: 6px!important;
  //border-radius: 16px!important;
  //background: #91BADC!important;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
}

.path {
  display: flex;
  gap: 16px;
  color: #758192;

  .path-main {
    cursor: pointer;
  }
}

.full-filter {
  .modal-dialog {
    margin: 0;
    right: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    z-index: 1051;
    .modal-content {
      min-width: 416px;
      height: 100%;
      border-radius: 0;
      border: 0;
      .page-header {
        .header-buttons{
          padding-right: 0px;
        }
      }
      .hff-title {
        display: flex;
        flex-direction: row;
        padding: 24px 24px 16px 24px;
        border: none;
        .hff-title-text {
          color: #213450;
          line-height: 32px;
          margin-right: auto;
        }
        .hff-close-wrapper {
          padding: 0 5px;
          .hff-close {
            color: #758192;
            cursor: pointer;
            &:hover {
              color: #1665A7;
            }
          }
        }
      }
      .hff-content {
        padding: 0;

        .filter-scroll-wrapper {
          height: 100%;
          .scroll {
            padding: 0;
            form {
              padding: 0px 24px 24px 24px;
            }
          }
        }
        .form-control-wrapper {
          .form-control {
            border: none;
            color: #758192;
            background: #F4F7FB;
          }
          .input-group{
            .form-control{
              padding: 9px 10px;
              color: #758192;
              font-size: 16px;
              line-height: 22.4px;              
            }
            .input-group-text{
              background: #F4F7FB;
            }
          }
          .form-label{
            font-weight: 500;
          }
          .form-select {
            border: none;
            background-color: #F4F7FB;
            padding: 9px 10px;
            font-size: 16px;
            line-height: 1.4;
            &.not-selected {
              color: #758192;
            }
          }
        }
      }
      .hff-footer {
        border: none;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
        padding: 16px 28px 32px;
        .hff-footer-text {
          width: 100%;
          text-align: center;
        }
        .hff-footer-buttons {
          width: 100%;
          margin: 16px 0 0 0;
          .hff-clear-data {
            margin-left: 8px;
            width: calc(50% - 8px);
          }
          .hff-clear-show {
            margin-right: 8px;
            width: calc(50% - 8px);
          }
        }
      }
    }
  }
  .filter-margin {
    margin-top: 20px;
  }
}

.box-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 57px;
  background: white;
  box-shadow: 0px 2px 10px 0px #00000021;
  border-radius: 4px;
  color: #758192;
  cursor: pointer;
  &:hover{
    color: #1665a7;
  }
}

.no-avtive{
  color: #A6AEB9;
}
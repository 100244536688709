.hosts-page{

  .hosts-wrapper {
    display: flex;
    flex-direction: column;
    gap: 22px;
    padding: 28px 32px 28px 32px;
  
    .host-table {
      display: flex;
      flex-direction: column;
  
      .grid-pagination{
        position: relative;
        justify-content: center;
        .pagination-info{
          position: absolute;
          left: 0;
        }
      }
      
      .frame {
        display: flex;
        height: auto;
        overflow: hidden;
      }
  
      .host-grid {
        .column-0 {
          width: 56px;
          cursor: pointer;
          position: relative;
        }
        .column-1 {
          width: 14.6%;
          max-width: 262px;
        }
        .column-2 {
          width: 16.4%;
        }
        .column-3 {
          width: 8.4%;
          max-width: 152px;
        }
        .column-4 {
          width: auto;
          max-width: 811px;
        }
        .column-5 {
          width: 10.4%;
          max-width: 277px;
        }
        .settings-column {
          width: 56px;
          .more-vert{
            color: #A6AEB9;
          }
          &:hover{
            color: #1665a7;
            cursor: pointer;
          }
        }
        .checkbox-column {
          width: 56px;
        }
        .control-box-button {
          color: #1665A7;
          &:hover {
            color: white;
            background: #1665A7;;
            cursor: pointer;
          }
        }
        
      }
      
    }
  
    .hosts-inputs-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .hosts-info, .hosts-inputs {
        display: flex;
        align-items: center;
        gap: 16px;
  
        input {
          height: 40px;
        }
  
        .page-header {
          .header-text {
            font-size: 24px;
            font-weight: 700;
          }
        }
  
      }
  
      .hosts-info {
        gap: 32px;
  
        &.variant {
          flex-direction: column;
          align-items: start;
          gap: 12px;
          font-size: 14px;
        }
      }
    }
  }
}

.hosts-more-wrapper {
  padding: 6px 0;
  min-width: 360px;
  .hosts-more-button {
    text-align: left;
    padding: 15px;
    border-radius: 0;
    color: #213450;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #b3b9c3;
    align-items: center;
    &:last-child {
      border-bottom: none;
    }
    .hosts-more-button-icon {
      width: 24px;
      margin-right: 6px;
      text-align: center;
    }
    .button-icons {
      margin: 0 auto;
      color: #1665a7;
    }
  }
}

.wsh-info-tooltip {
  padding: 24px;
  min-width: 300px;
  &.host-problems {
    min-width: 450px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    .problems-scroll-wrapper {
      height: 100%;
      flex: 1;
    }
  }
  .wsh-info-title {
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    padding: 0 16px;
    .close {
      margin-left: auto;
    }
  }

  .wsh-info-table {
    width: 100%;
    margin-top: 12px;
    .wsh-info-table-row {
      border-top: 1px solid #E2E8F1;
      .wsh-info-table-cell {
        height: 56px;
        padding: 16px;
        vertical-align: middle;
        text-align: left;
        min-width: 164px;
        &.wsh-key {
          font-size: 16px;
          font-weight: 500;
        }
        &.wsh-value {
          font-size: 16px;
          font-weight: 400;
        }
        &.wsh-date {
          font-size: 16px;
          font-weight: 400;
          width: 145px;
          min-width: 145px;
          text-align: center;
        }
        &.wsh-message {
          font-size: 16px;
          font-weight: 400;
        }

        &:first-child {
          border-right: 1px solid #E2E8F1;
        }
      }
      &:last-child {
        border-bottom: 1px solid #E2E8F1;
      }
    }
  }

  .mpf-more-actions {
    width: 100% !important;
    bottom: 0 !important;
    top: 0!important;
    left: 0!important;
    right: 0!important;
    background: rgba(33, 52, 80, 0.35);
    .hosts-more-tooltip {
      top: auto;
      bottom: 0;
      width: 100%;
      max-height: initial!important;
      border-radius: 4px 4px 0 0;
      .hosts-tooltip-popup {
        .hosts-more-wrapper {
          .wsh-info-title {
            display: flex;
            flex-direction: row;
            padding: 16px;
            font-size: 16px;
            font-weight: 500;
            line-height: 120%; /* 19.2px */
            .close {
              margin-left: auto;
              margin-right: 4px;
            }
          }
          .hosts-more-button {
            padding: 12px 16px;
            font-size: 16px;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
          }
        }
      }
    }
  }
}

.host-popup-add, .host-popup-edit{
  .modal-dialog {
    z-index: 1051;

    .header-buttons{
      justify-content: space-between;
    }

    .hff-close-wrapper {
      padding: 0 5px;
      .hff-close {
        color: #758192;
        cursor: pointer;
        &:hover {
          color: #1665A7;
        }
      }
    }

    .hff-content{
      display: flex;
      flex-direction: column;
      gap: 32px;

      .input-form{
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        .form-control-wrapper:last-child .form-control{
          padding: 15px 16px;
        }
        .input-group{
          box-shadow: none;
        }
        .input-group-text{
          background-color: #F4F7FB;
          box-shadow: none;
        }
      }
      .form-description{
        display: flex;
        flex-direction: column;
        gap: 16px;
        .form-description-title{
          font-size: 16px;
          color: #001636DE;
          font-weight: 682;
        }
      }
      .form-equpment{
        display: flex;
        flex-direction: column;
        gap: 16px;

        .form-equpment-title{
          font-size: 16px;
          color: #001636DE;
          font-weight: 682;
        }
        .equipments-group{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-start;
          .equipments-group-item{
            position: relative;
            display: flex;
            justify-content: start;
            height: 90px;
            width: 142px;
            margin-top: 16px;
            padding-top: 12px;
            border: 1px solid #E2E8F1;
            border-radius: 4px;
            align-items: center;
            flex-direction: column;
            margin-right: 8px;
            user-select: none;
            cursor: pointer;
            .equipments-group-items-title{
              text-align: center;
              max-width: 124px;
            }
            .item-circle{
              position: absolute;
              right: 12px;
              top: 12px;
              display: none;
              &.isSelected{
                display: flex;
              }
            }
          }
        }
      }
      .form-control-wrapper{
        width: 100%;
        margin-bottom: 16px;
        &:last-child{
          margin-bottom: 0;
          .form-control{
            padding: 12px 16px;
          }
        }
        &.middle{
          width: 100%;
        }
        &.short{
          width: 390px;
        }
        .form-label{
          font-weight: 500;
          margin-bottom: 12px;
        }
        .form-control{
          padding: 15px 16px;
          border: none;
          background-color: #F4F7FB;
          line-height: 22px;
        }
        .form-label{
          line-height: 19px;
        }
      }
    }

    .hff-footer{
      justify-content: center;
    }
  }
  .modal-xl {
    --bs-modal-width: 836px;
  }
}
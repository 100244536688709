.setting-column{
  .modal-dialog {
    z-index: 1051;
    .modal-content{
      height: 857px;
      width: 508px;
      .hff-content{
        overflow: hidden;
        padding: 0 16px;
        .hff-wrapper {
          width: calc(100% - 32px);
          height: 100%;
          position: absolute;
          .move-icon {
            pointer-events: none;
          }
        }
      }
    }
    .modal-body{
      padding: 24px 24px;
      user-select: none;
      position: relative;
    }
    .hff-footer {
      border: none;
      border-top: 1px solid #E2E8F1;
      padding: 16px 28px 32px;
      .hff-footer-text {
        width: 100%;
        text-align: center;
      }
      .hff-footer-buttons {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 10px 0 0 0;
        .hff-clear-data {
          margin-left: 8px;
          width: calc(43% - 8px);
        }
        .hff-clear-show {
          margin-right: 8px;
          width: calc(43% - 8px);
        }
      }
    }
  }
}

.drag-cursor{
  cursor: grabbing !important;
  overflow-x: hidden;
}

.column-row {
  position: absolute;
  display: flex;
  user-select: none;
  left: 0;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 12px;
  transition: top 300ms;
  background-color: white;
  cursor: grab;
  .form-check-label{
    margin-left: 22.75px;
    margin-top: 5px;
    cursor: inherit;
    pointer-events: none;
  }
  .form-check-input{
    margin-top: 3px;
    cursor: pointer;
  }
  .move-icon{
    position: absolute;
    right: 12px;
    color: #B3B9C3;
  }
  &:hover{
    color: #1665A7;
  }
  &.ghost {
    position: absolute;
    z-index: 99999;
    transition: none;
    cursor: grabbing;
  }
}

.range-date-picker {
  .data-picker-text {
    margin-left: 0!important;
    .input-group {
      border-radius: 4px;
      border: 0;
      .input-group-text {
        .calendar-week {
          color: #1665A7;
        }
      }
      .form-control {
        font-size: 16px;
        font-weight: 400;
        padding-left: 0;
        min-width: 170px;
      }
      &:focus-within {
        border: 0;
        box-shadow: 0 0 0 1px #1665A7, 0 10px 30px 0 rgba(0, 0, 0, 0.15);
      }
    }
  }
}
  .data-picker-wrapper {
    padding: 0;
    border-radius: 4px;
    background: #FFF;
    border: none;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
    .rmdp-wrapper {
      padding: 24px 24px 0 24px;
      border-radius: 0;
      box-shadow: none;
      width: 354px;
      .rmdp-top-class {
        width: 100%;
      }
      .rmdp-calendar {
        padding: 0;
        width: 100%;
        .rmdp-header {
          margin-top: 0;
          height: 40px;
          padding: 0;
          div:first-child {
            height: 100%;
          }
          .rmdp-arrow-container {
            width: 40px;
            height: 40px;
            border: 2px solid #758192;
            align-items: center;
            margin: 0;
            .rmdp-arrow {
              width: 9px;
              height: 9px;
              margin-top: 0;
              border-color: #758192;
            }
            &:hover {
              border: 2px solid #085290;
              background: transparent;
              box-shadow: none;
              .rmdp-arrow {
                border-color: #085290;
              }
            }
          }
          .rmdp-left {
            position: absolute;
            left: auto;
            right: 52px;
          }
          .rmdp-header-values {
            position: absolute;
            top: 0;
            color: #fff;
            font-size: 18px;
            font-style: normal;
            font-weight: 682;
            line-height: 140%;
            span {
              color: #213450;
              padding-left: 0;
            }
          }
          .rmdp-right {
            position: absolute;
            right: 0;
          }
        }
        .rmdp-day-picker {
          //margin-top: 10px;
          margin-top: 0;
          padding: 0;
          .rmdp-week {
            margin-top: 12px;
            .rmdp-week-day {
              height: 40px;
              width: 44px;
              color: #758192;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              text-align: center;
            }
            .rmdp-day {
              height: 40px;
              width: 40px;
              color: #213450;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              span {
                &:hover {
                  background: #1665A7;
                  color: #ffffff;
                  width: 40px;
                  height: 40px;
                  // left: 0;
                  // top: 0;
                  // right: 0;
                  // border: 0;
                }
              }
              &.rmdp-selected span {
                background-color: #1665A7;
                height: 40px;
                width: 40px;
              }
              &.rmdp-today {
                span {
                  background: transparent;
                  color: #213450;
                }
              }
              &.rmdp-range-hover,
              &.rmdp-range {
                background-color: #91BADC;
                box-shadow: none;
                &.start,
                &.end {
                  span {
                    width: 40px;
                    height: 40px;
                    font-size: 16px;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    top: 0;
                    background: #1665A7;
                    color: #ffffff;
                  }
                }
                &.end {
                  span {
                    left: auto;
                  }
                }
              }
              &.rmdp-day-hidden {
                span {
                  background: transparent;
                }
              }
            }
            &:first-child {
              border-bottom: 1px solid #D4D9E3;
              padding-bottom: 12px;
            }
          }
        }
        .rmdp-month-picker {
          box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
          .rmdp-day {
            line-height: 16px;
          }
          .rmdp-selected span{
            background: #1665A7;
          }
          .rmdp-today span{
            background: transparent;
            color: #000;
          }
        }
        .rmdp-year-picker {
          box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
          .rmdp-day {
            line-height: 16px;
          }
          .rmdp-selected span{
            background: #1665A7;
            box-shadow: none;
          }
        }
      }
    }
    .data-picker-filter-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 0 24px 24px;
      .data-picker-filter-button {
        color: #8C949D;
        cursor: pointer;
        line-height: 100%;
        z-index: 1;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        text-decoration: none;
        padding: 12px 0;
        text-align: center;
        margin-right: 12px;
        margin-top: 12px;
        width: 94px;
        border-radius: 4px;
        border: 2px solid #A6AEB9;
        background: #FFF;
        &.active {
          border: 2px solid #085290;
          color:  #085290;
          background: #EAF5FF;
        }
      }
    }
}
.margin-right{
  margin-right: 15px;
}

@media (max-width: 1799px) {
    .data-picker-wrapper {
      .rmdp-wrapper {
        width: 322px;
        .rmdp-calendar {
          .rmdp-header {
            .rmdp-arrow-container {
              width: 32px;
              height: 32px;
            }
          }
          .rmdp-day-picker {
            .rmdp-week {
              .rmdp-week-day {
                height: 40px;
                width: 40px;
              }
              .rmdp-day {
                height: 40px;
                width: 40px;
                span {
                  &:hover {
                    width: 40px;
                    height: 40px;
                  }
                }
                &.rmdp-range {
                  &.start,
                  &.end {
                    span {
                      width: 30px;
                      height: 30px;
                    }
                  }
                }
              }
            }
          }
          .rmdp-month-picker {}
          .rmdp-year-picker {}
        }
      }
      .data-picker-filter-buttons {
        .data-picker-filter-button {
          width: 82px;
          font-size: 14px;
          font-weight: 500;
          padding: 7px 0;
        }
      }
    }
}

.payment-log-page {

  .plp-container {
    display: flex;
    flex-direction: column;
    gap: 22px;
    padding: 28px 32px 28px 32px;
  
    .plp-inputs-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .plp-title-page {
        display: flex;
        align-items: center;
        gap: 32px;

        &.variant {
          flex-direction: column;
          align-items: start;
          gap: 12px;
          font-size: 14px;
        }
      }

      .plp-inputs {
        display: flex;
        align-items: center;
        gap: 16px;
  
        input {
          height: 40px;
        }
      }
    }

    .plp-table {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .frame {
        display: flex;
        height: auto;
        overflow: hidden;
      }

      .grid-pagination{
        position: relative;
        justify-content: center;
        .pagination-info{
          position: absolute;
          left: 0;
        }
      }

      .plp-grid {
        .column-0 {
          width: 56px;
          cursor: pointer;
          position: relative;
        }
        .column-1 {
          width: 7.54%;
        }
        .column-2 {
          width: 7.54%;
        }
        .column-3 {
          width: 8.8%;
        }
        .column-4 {
          width: 10.24%;
        }
        .column-5 {
          width: 9.5%;
        }
        .column-6 {
          width: 10.24%;
        }
        .column-7 {
          width: 11.3%;
        }
        .column-8 {
          width: 10.24%;
        }
        .column-9 {
          width: 9.2%;
        }
        .column-10 {
          width: auto;
        }
        .settings-column {
          width: 56px;
          .more-vert{
            color: #A6AEB9;
          }
          &:hover{
            color: #1665a7;
            cursor: pointer;
          }
        }
        .checkbox-column {
          width: 56px;
        }
      }

      .table .data-table-content .data-table-row .data-table-cell {
        height: 60px;
      }
      
      .table .data-table-header .table-header-row .table-header-cell{
        padding: 16px;
      }
      
    }
  
  }
}

.plp-popup{
  border-radius: 4px;
  .modal-dialog {
    z-index: 999999999;
    .modal-content {
      height: 778px;
      border-radius: 4px;
      border: 0;
      .hff-title {
        padding: 24px 32px 16px 32px;
        border-bottom: 1px solid #D4D9E3;
        position: relative;

        .header-buttons{
          padding: 0;
          display: flex;
          justify-content: space-between;

          .hff-close-wrapper {
            padding: 0;
            .hff-close {
              color: #758192;
              cursor: pointer;
              &:hover {
                color: #1665A7;
              }
            }
          }
        }
        
      }
      .hff-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 24px 32px;
      
        .plp-basic-information-box{
          display: flex;
          flex-direction: column;
          width: 100%;
          border: 1px solid #E2E8F1;
          border-radius: 4px;

          .plp-basic-information-box-item{
            display: flex;
            height: 56px;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid #E2E8F1;

            &:last-child{
              border-bottom: 0;
            }

            .plp-basic-information-box-item-title, .plp-basic-information-box-item-content{
              display: flex;
              width: 50%;
              justify-content: flex-start;
              font-size: 16px;
              padding-left: 16px;
              align-items: center;
              height: 100%;
            }
            .plp-basic-information-box-item-title{
              font-weight: 500;
              border-right: 1px solid #E2E8F1;
            }
          }
        }

        .form-control-wrapper{
          width: 100%;
          margin-bottom: 16px;
          &:last-child{
            margin-bottom: 0;
          }
          &.middle{
            width: 422px;
          }
          &.short{
            width: 204px;
          }
          .form-label{
            font-weight: 500;
            margin-bottom: 12px;
          }
          .form-control{
            padding: 15px 16px;
            border: none;
            background-color: #F4F7FB;
            line-height: 22.4px;
          }
        }
      }
      .hff-footer{
        justify-content: center;
        align-items: center;
        height: 80px;
        gap: 16px;
      }
    }
  }
  .modal-xl {
    --bs-modal-width: 524px;
  }
}
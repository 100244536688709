.link-file-input{
  .form-file-input {
    pointer-events: none;
    outline: none;
  }
  .form-file-button {
    width: 100%;
    background: transparent;
    border: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.65;
  }
}
.button-file-input {
  display: flex;
  flex-direction: column;
  .form-file-input {
    width: .1px;
    height: .1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
  }
  .form-file-label {
    position: relative;
    justify-content: flex-end;
    width: fit-content;
    max-width: 100%;
    .form-file-button {
      border-radius: 4px;
      color: #fff;
      fill: #fff;
      background-color: #1665A7;
      border-color: #1665A7;
      box-shadow: 0 2px 10px rgba(22, 101, 167, 0.4);
      display: inline-block;
      font-weight: 400;
      line-height: 1.5;
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        color: #fff;
        background-color: #125185;
        border-color: #104a7a;
      }
      &.disabled {
        color: #fff;
        background-color: #1665A7;
        border-color: #1665A7;
        pointer-events: none;
        opacity: 0.65;
      }
    }
  }
  &.disabled {
    .form-file-label {
      .form-file-button {
        pointer-events: none;
        opacity: 0.65;
      }
    }
  }
}

.notifications-wrapper {
  position: absolute;
  z-index: 99999;
  width: 450px;
  padding: 10px 20px 40px 30px;
  height: auto;
  top: 0;
  bottom: auto;
  left: auto;
  right: 0;
  overflow: hidden;
  .notification {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    //cursor: pointer;
    border-radius: 0 4px 4px 0;
    opacity: 0;
    transition: 0.3s ease-in-out;
    transform: translate3d(500px, 0, 0);
    -webkit-transform: translate3d(500px, 0, 0);
    will-change: transform, opacity;
    background: #FFF;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
    padding: 24px;
    margin-top: 10px;
    .notification-title {
      color: rgba(0, 22, 54, 0.87);
      font-size: 18px;
      font-weight: 682;
      line-height: 140%;
    }
    .notification-message {
      color: rgba(0, 22, 54, 0.87);
      font-size: 16px;
      line-height: 140%;
    }
    .notification-icon-wrapper {
      display: flex;
      margin-right: 16px;
      .notification-icon {
        width: 36px;
        height: 39px;
      }
    }
    .notification-dismiss {
      cursor: pointer;
      width: 14px;
      height: 14px;
      position: absolute;
      top: 24px;
      right: 24px;
      margin-top: 3px;
    }
    &.is-visible {
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    //&.is-hidden {
    //  opacity: 0;
    //  transition: 0.3s ease-out;
    //}
    &.notification-error {
      border-left: 3px solid #B71919;
      .notification-icon-wrapper {
        .notification-icon {
          height: 41px;
        }
      }
    }
    &.notification-warning {
      border-left: 3px solid #FFA41F;
      .notification-icon-wrapper {
        .notification-icon {
          height: 41px;
        }
      }
    }
    &.notification-success {
      border-left: 3px solid #09912F;
    }
  }

  &.mobile {
    width: 100%;
    padding: 8px 8px 40px 8px;
  }
}

.switch-control-wrapper {
  .form-switch {
    padding: 4px 0;
    margin: 0;
    .form-switch-label {
      color: #213450;
      font-size: 16px;
      line-height: 140%;
    }
    .form-switch-input {
      position: relative;
      margin-left: 0;
      float: right;
      margin-right: 9px;
      width: 34px;
      height: 14px;
      border-radius: 10px;
      margin-top: 0.25em;
      vertical-align: top;
      border: none;
      appearance: none;
      -webkit-print-color-adjust: exact;
      background-color: rgba(0, 22, 54, 0.12);
      cursor: pointer;
      &:before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        position: absolute;
        left: -3px;
        top: -3px;
        border-radius: 20px;
        background: #758192;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12),
                    0 1px 1px 0 rgba(0, 0, 0, 0.14),
                    0 2px 1px -1px rgba(0, 0, 0, 0.20);
        transition: 0.3s;
      }
      &:checked[type=checkbox] {
        background-color: #91BADC;
        &:before {
          left: auto;
          right: -3px;
          border-radius: 20px;
          background: #1665A7;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12),
                      0 1px 1px 0 rgba(0, 0, 0, 0.14),
                      0 2px 1px -1px rgba(0, 0, 0, 0.20);
          transition: 0.3s;
        }
      }
    }
  }
}

.czh-page {
  .czh-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding: 32px;
    
    .czh-loader-container{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      height: 892px;
      box-shadow: 0px 2px 10px 0px #00000021;
      border-radius: 4px;
      
      .czh-img-loader{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 632px;
        height: 204px;
        gap: 16px;
        background-color: #EAF5FF;
        border-radius: 4px;
        border: 1px dashed #91BADC;
        .czh-button-description{
          display: flex;
          text-align: center;
          width: 389px;
        }
        .czh-button-loader{
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          width: 245px;
          height: 40px;
          border-radius: 4px;
          box-shadow: 0px 2px 10px 0px #1665A766;
          padding: 10 16 10 16;
          font-weight: 500;
          background-color: white;
          color: #1665A7;
          .form-file-button{
            background-color: white;
            color: #1665A7;
            box-shadow: none;
          }
        }
      }
      .czh-img-edit-container{
        position: relative;
        .czh-box-buttons{
          position: absolute;
          display: flex;
          bottom: 0;
          width: 100%;
          justify-content: center;
          gap: 16px;
          .czh-edit-button{
            justify-content: center;
            align-items: center;
            display: flex;
            width: 172px;
            color: white;
            font-size: 500;
            height: 40px;
            border-radius: 4px;
            background-color: #1665A7;
            cursor: pointer;
            &.save{
              background-color: white;
              color: #1665A7;
              height: 40px;
              border: 2px solid #1665A7;
            }
            &:hover{
              background-color: #085290;
              &.save{
                background-color: #EAF5FF;
              }
            }
          }
        }
      }
    }
  
    .czh-assignment-container{
      display: flex;
      justify-content: space-between;

      .czh-assignment{
        display: flex;
        padding: 24px;
        height: 892px;
        width: 74%;        
        gap: 16px;
        align-items: space-between;
        background-color: white;
        flex-direction: column;
        flex-wrap: wrap;
        border-radius: 4px;
        box-shadow: 0px 2px 10px 0px #00000021;

        &.empty{
          gap: 359px;
          align-items: center;
          .czh-assignment-title{
            width: 100%;
            display: flex;
            justify-content: start;
          }
          .czh-assignment-empty{
            width: 303px;
            text-align: center;
            display: flex;
            font-weight: 500;
            justify-content: center;
            align-items: center;
            color: #00163661;
          }
        }

        .czh-assignment-title{
          color: #001636DE;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
        }

        .czh-assignment-setting-box{
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: 510px;
          max-height: 325px;

          &.map-mode{
            flex-shrink: 0;
            width: 746px;
            min-height: 548px;
            font-size: 18px;
            font-weight: 500;
            gap: 16px;
            
            .map{
              padding: 0;
            }

            .map-button-box{
              right: 16px;
              top: 178px;
            }
          
            .map-button-center{
              right: 16px;
              top: 16px;
            }
          
            .map-button-editor{
              right: 96px;
              top: 16px;
            }
          }

          &.grid-mode{
            .czh-assignment-item{
              display: flex;
              width: 100%;
              justify-content: space-between;
              .form-control-wrapper{
                width: 49%;
              }
            }
          }

          .czh-assignment-setting-title{
            font-weight: 500;
            line-height: 19.2px;
          }
          
          .czh-assignment-create-host { //ToDo Вынести в общий компонент
            display: flex;
            justify-content: center;
            align-items: center;
            width: 135px;
            height: 32px;
            font-size: 16px;
            border-radius: 4px;
            border: 2px solid #1665A7;
            color: #1665A7;
            font-weight: 500;
            gap: 8px;
            cursor: pointer;

            &:hover{
              background-color: #EAF5FF;
            }

            &.without-border{
              border: none;
            }
          }

          .icon {
            position: absolute;
            right: 14px;
            cursor: pointer;
            top: 14px;
          }
          
          .form-control{
            position: relative;
            background-color: #F4F7FB;
            padding: 15px 16px;
            font-size: 16px;
            line-height: 22px;
            border-radius: 4px;
            border: none;
            color: #213450;
          }
        }
      }
    }
  }
}

.event-log-page {
  .elp-container {
    display: flex;
    flex-direction: column;
    gap: 22px;
    padding: 28px 32px 28px 32px;
  
    .elp-inputs-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .elp-title-page {
        display: flex;
        align-items: center;
        gap: 32px;

        &.variant {
          flex-direction: column;
          align-items: start;
          gap: 12px;
          font-size: 14px;
        }
      }

      .elp-inputs {
        display: flex;
        align-items: center;
        gap: 16px;
  
        input {
          height: 40px;
        }
      }
    }

    .elp-table {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .grid-pagination{
        position: relative;
        justify-content: center;
        .pagination-info{
          position: absolute;
          left: 0;
        }
      }

      .frame {
        display: flex;
        height: auto;
        overflow: hidden;
      }

      .elp-grid {
        .column-0 {
          width: 56px;
          cursor: pointer;
          position: relative;
        }
        .column-1 {
          width: 10.3%;
        }
        .column-2 {
          width: 10%;
        }
        .column-3 {
          width: 4.5%;
        }
        .column-4 {
          width: auto;
        }
        .column-5 {
          width: 14.8%;
        }
        .column-6 {
          width: 7.4%;
        }
        .column-7 {
          width: 14.8%;
        }
        .settings-column {
          width: 56px;
          .more-vert{
            color: #A6AEB9;
          }
          &:hover{
            color: #1665a7;
            cursor: pointer;
          }
        }
        .checkbox-column {
          width: 56px;
        }
      }

      .table .data-table-content .data-table-row .data-table-cell {
        height: 60px;
      }
      
      .table .data-table-header .table-header-row .table-header-cell{
        padding: 16px;
      }
      
      .sticky-table-wrapper .table .data-table-header{
        box-shadow: none;
      }
    } 
  }
}

.elp-popup{
  z-index: 10001;
  .modal-dialog {
    z-index: 10002;
    .modal-content {
      height: 713px;
      border-radius: 4px;
      border: 0;
      .hff-title {
        padding: 24px;
        border-bottom: 1px solid #D4D9E3;
        position: relative;

        .header-buttons{
          display: flex;
          align-items: flex-start;
          gap: 12px;
          flex-direction: column;

          .hff-title-text {
            color: #213450;
            line-height: 32px;
            margin-right: auto;
          }
          
          .hff-close-wrapper {
            position: absolute;
            top: 41px;
            right: 37px;
            
            .hff-close {
              color: #758192;
              cursor: pointer;
              &:hover {
                color: #1665A7;
              }
            }
          }
        }
      }
      .hff-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 24px;
        overflow: hidden;
        
        .form-control-wrapper{
          width: 100%;
          margin-bottom: 16px;
          &:last-child{
            margin-bottom: 0;
            .form-control{
              padding: 12px 16px;
            }
          }
          &.middle{
            width: 422px;
          }
          &.short{
            width: 204px;
          }
          .form-label{
            font-weight: 500;
            margin-bottom: 12px;
          }
          .form-control{
            padding: 15px 16px;
            border: none;
            background-color: #F4F7FB;
            line-height: 22px;
          }
          .form-label{
            line-height: 19px;
          }
        }
      }
      .hff-footer{
        justify-content: center;
        align-items: center;
        height: 80px;
        gap: 16px;
      }
    }
  }
  .modal-xl {
    --bs-modal-width: 688px;
  }
}